import React from 'react';
import PropTypes from 'prop-types';

import { dateFormat, fixDateForAllBrowsers } from '../helpers/datetime';

const DateLabel = ({ date }) => {
  const formattedDate = fixDateForAllBrowsers(date);
  const dateObj = new Date(formattedDate);

  return <>{dateFormat.format(dateObj)}</>;
};

DateLabel.defaultProps = {
  date: '',
};

DateLabel.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default DateLabel;
