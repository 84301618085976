import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import Link from '@material-ui/core/Link';

import { Link as RouterLink } from 'react-router-dom';

import Copyright from './Copyright';

const Footer = () => {
  return (
    <FooterCnt my={5} pt={5} pb={5}>
      <Grid container spacing={4}>
        <Grid item xs={12} md>
          <Copyright />
        </Grid>
        <Grid item>
          <Link variant="body2" component={RouterLink} to="/cookies">
            Cookies
          </Link>
        </Grid>
        <Grid item>
          <Link variant="body2" component={RouterLink} to="/privacy">
            Privacy
          </Link>
        </Grid>
        <Grid item>
          <Link variant="body2" component={RouterLink} to="/terms">
            Terms
          </Link>
        </Grid>
        <Grid item>
          <Link variant="body2" component={RouterLink} to="/contact">
            Contact
          </Link>
        </Grid>
      </Grid>
    </FooterCnt>
  );
};

const FooterCnt = styled(Box)`
  border-top: 1px solid ${({ theme }) => theme.palette.grey[400]};
`;

export default Footer;
