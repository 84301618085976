import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import Manage from './Manage';
import List from './List';

const Estimates = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/manage/:estimateId`} component={Manage} />
      <Route exact path={path} component={List} />
    </Switch>
  );
};

export default Estimates;
