import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const EstimateIcon = props => {
  return (
    <SvgIcon {...props} viewBox="406 1032.833 183.333 229.167">
      <g>
        <path d=" M 531.888 1177.058 C 531.876 1174.007 531.492 1170.969 530.742 1168.011 L 541.011 1159.598 L 532.827 1145.411 L 520.255 1149.929 C 515.87 1145.733 510.534 1142.663 504.704 1140.98 L 502.576 1127.95 L 486.206 1127.95 L 484.002 1140.98 C 478.076 1142.672 472.762 1145.782 468.418 1149.929 L 455.956 1145.411 L 447.771 1159.587 L 457.964 1167.837 C 457.189 1170.85 456.79 1173.947 456.774 1177.058 C 456.774 1180.201 457.2 1183.224 457.931 1186.138 L 447.771 1194.519 L 455.956 1208.695 L 468.244 1204.035 C 472.609 1208.258 477.989 1211.423 483.991 1213.137 L 486.206 1226.167 L 502.576 1226.167 L 504.715 1213.126 C 510.623 1211.426 516.023 1208.301 520.44 1204.024 L 532.838 1208.706 L 541.022 1194.53 L 530.753 1186.116 C 531.492 1183.153 531.873 1180.112 531.888 1177.058 Z " />
        <path
          d=" M 494.413 1154.511 C 492.896 1154.511 492.245 1155.859 492.196 1156.63 L 491.329 1172.915 C 489.679 1173.952 488.535 1175.734 488.535 1177.831 C 488.535 1181.06 491.187 1183.709 494.413 1183.709 C 494.583 1183.709 494.728 1183.626 494.894 1183.613 L 508.001 1195.372 C 508.605 1195.899 510.169 1196.384 511.569 1194.987 C 512.978 1193.587 512.568 1191.924 512.05 1191.323 L 500.291 1177.927 L 500.291 1177.831 C 500.291 1175.663 499.064 1173.831 497.303 1172.82 L 496.63 1156.63 C 496.593 1155.859 495.921 1154.508 494.413 1154.508 L 494.413 1154.511 Z "
          fill="rgb(255,255,255)"
        />
      </g>
      <path d=" M 520.583 1032.833 L 428.917 1032.833 C 416.313 1032.833 406 1043.146 406 1055.75 L 406 1239.083 C 406 1251.688 416.198 1262 428.802 1262 L 566.417 1262 C 579.021 1262 589.333 1251.688 589.333 1239.083 L 589.333 1101.583 L 520.583 1032.833 Z  M 566.417 1239.083 L 428.917 1239.083 L 428.917 1055.75 L 509.125 1055.75 L 509.125 1113.042 L 566.417 1113.042 L 566.417 1239.083 Z " />
    </SvgIcon>
  );
};

export default EstimateIcon;
