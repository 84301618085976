import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import InputBase from '@material-ui/core/InputBase';
import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import styled from 'styled-components';

import api from '../../helpers/api';
import { useCountryById } from '../../hooks/countries';
import CustomerInfo from '../CustomerInfo';
import PrimaryDivider from '../PrimaryDivider';
import ItemAvatar from '../ItemAvatar';
import { useGlobalState } from '../GlobalState';
import { AccentTypography } from './ManageEstimate';
import { useEstimateStore } from './EstimateStore';
import ManageProject from './ManageProject';
import { useWebsocketConn } from './RealTimeProvider';

const filter = createFilterOptions();

const cutomerFields = {
  currency_id: null,
  country_id: null,
  contact_name: '',
  contact_phone: '',
  contact_email: '',
  address_line_1: '',
  address_line_2: '',
  city: '',
  state: '',
  zip: '',
};

const ManageCustomer = () => {
  const ws = useWebsocketConn();
  const { estimate, dispatch } = useEstimateStore();
  const { globalState } = useGlobalState();

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState('');

  const [customers, setCustomers] = React.useState([]);
  const [customer, setCustomer] = React.useState(null);
  const [dialogCustomer, setDialogCustomer] = React.useState(null);

  const customerCountry = useCountryById(estimate?.customer?.country_id);

  React.useEffect(() => {
    api
      .post('/customer/get-all', {
        workspace_id: globalState.client.current_workspace.workspace_id,
      })
      .then(response => {
        setCustomers(response.data.result.filter(customer => customer.is_archived === false));
      })
      .catch(e => {
        console.log(e);
      });
  }, [globalState]);

  React.useEffect(() => {
    setCustomer(estimate.customer ? estimate.customer : null);
  }, [estimate.customer]);

  const saveEstimate = params => {
    const reqParams = {
      ...estimate,
      ...params,
    };

    api
      .post('/estimate/update', reqParams)
      .then(response => {
        if (response.data.status.code === 0) {
          dispatch({ type: 'UPDATE_ESTIMATE', payload: response.data.result });

          ws.send({
            action: 'updatedEstimate',
            payload: JSON.stringify({
              customer_id: response.data.result.customer_id,
              project_id: response.data.result.project_id,
              customer: response.data.result.customer,
              project: response.data.result.project,
            }),
          });
        }
      })
      .catch(e => {
        console.log(e);
      })
      .then(() => {});
  };

  const onCloseDialog = () => {
    setOpen(false);

    ws.send({ action: 'unlockSection' });
  };

  const onOpenDialog = () => {
    setOpen(true);

    ws.send({
      action: 'lockSection',
      payload: 'customer',
    });
  };

  const onEditCustomer = () => {
    onOpenDialog();

    setDialogCustomer({
      ...customer,
      contact_name: customer.contact_name ? customer.contact_name : '',
      contact_phone: customer.contact_phone ? customer.contact_phone : '',
      contact_email: customer.contact_email ? customer.contact_email : '',
      address_line_1: customer.address_line_1 ? customer.address_line_1 : '',
      address_line_2: customer.address_line_2 ? customer.address_line_2 : '',
      city: customer.city ? customer.city : '',
      state: customer.state ? customer.state : '',
      zip: customer.zip ? customer.zip : '',
    });
  };

  const onSave = () => {
    setErrors({});
    setLoading(true);

    const url =
      dialogCustomer && dialogCustomer.customer_id ? '/customer/update' : '/customer/create';

    api
      .post(url, dialogCustomer)
      .then(response => {
        if (response.data.status.code !== 0) {
          setErrors(response.data.errors);
        } else {
          onCloseDialog();
          setCustomer(response.data.result);
          saveEstimate({
            customer_id: response.data.result.customer_id,
          });
        }
      })
      .catch(e => {
        setErrors({
          general: e.toString(),
        });
      })
      .then(() => {
        setLoading(false);
      });
  };

  const lockInfo = estimate.locked_sections?.find(lockInfo => lockInfo.section === 'customer');
  const lockedBy = globalState.client.current_workspace.members.find(
    member => lockInfo?.client_id === member.client_id
  );

  return (
    <>
      <Box display="flex" flexDirection="column" position="relative">
        {lockedBy && (
          <LockedItemAvatar
            text={lockedBy.client.full_name}
            resource={lockedBy.client.image}
            online
            size={5}
          />
        )}
        <Box display="flex" justifyContent="space-between" alignItems="center" px={1}>
          <AccentTypography variant="h6">For</AccentTypography>
          <Button
            color="primary"
            size="small"
            onClick={onEditCustomer}
            disabled={!customer || Boolean(lockInfo)}
          >
            Edit customer
          </Button>
        </Box>
        <PrimaryDivider />
        <CustomerInfoCnt px={1} pt={2} locked={lockInfo}>
          <Box display="flex" alignItems="center" pb={1} height={40}>
            <Autocomplete
              value={customer}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  onOpenDialog();
                  setDialogCustomer({
                    ...cutomerFields,
                    name: newValue,
                    workspace_id: globalState.client.current_workspace.workspace_id,
                  });
                } else if (newValue && newValue.inputValue) {
                  onOpenDialog();
                  setDialogCustomer({
                    ...cutomerFields,
                    name: newValue.inputValue,
                    workspace_id: globalState.client.current_workspace.workspace_id,
                  });
                } else {
                  setCustomer(newValue);
                  saveEstimate({
                    customer_id: newValue ? newValue.customer_id : null,
                    project_id: null,
                  });
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    name: `Add "${params.inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              fullWidth
              options={customers.filter(customer => customer.is_archived === false)}
              getOptionLabel={option => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }

                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }

                // Regular option
                return option.name;
              }}
              renderOption={option => option.name}
              freeSolo
              renderInput={params => (
                <div ref={params.InputProps.ref}>
                  <StyledInputBase
                    fullWidth
                    placeholder="Type customer name"
                    {...params.inputProps}
                  />
                </div>
              )}
            />
          </Box>

          {customer && (customer.contact_name || customer.contact_email) && (
            <Typography variant="body2" color="textSecondary">
              {customer.contact_name}
              {customer.contact_email && ' <'}
              {customer.contact_email}
              {customer.contact_email && '>'}
            </Typography>
          )}
          {customer && customer.address_line_1 && (
            <Typography variant="body2" color="textSecondary">
              {customer.address_line_1}
            </Typography>
          )}
          {customer && (customer.city || customer.state || customer.zip) && (
            <Typography variant="body2" color="textSecondary">
              {customer.city}
              {customer.city && ', '}
              {customer.state}
              {customer.state && ' '}
              {customer.zip}
            </Typography>
          )}

          {customerCountry && (
            <Typography variant="body2" color="textSecondary">
              {customerCountry.name}
            </Typography>
          )}

          {customer && (
            <ManageProject
              workspaceId={globalState.client.current_workspace.workspace_id}
              customerId={customer.customer_id}
              projectId={estimate.project_id}
              onChangeProject={newProject => {
                saveEstimate({
                  project_id: newProject ? newProject.project_id : null,
                });
              }}
            />
          )}
        </CustomerInfoCnt>
      </Box>

      <Dialog open={open} onClose={onCloseDialog} fullWidth>
        <DialogTitle>
          Customer details
          <Typography variant="body2" color="textSecondary">
            Filling more information will look better in exported documents.
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={2}>
            <CustomerInfo
              customer={dialogCustomer}
              setCustomer={setDialogCustomer}
              errors={errors}
            />

            {loading && (
              <Grid item>
                <LinearProgress />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseDialog}>Cancel</Button>
          <Button onClick={onSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const StyledInputBase = styled(InputBase)`
  & input {
    margin-left: -8px;
    padding: 6px 5px 6px 7px;
    border: 1px solid transparent;
    transition: border 0.2s;
  }

  & input:hover {
    border: 1px solid ${({ theme }) => theme.palette.divider};
  }

  & input:focus {
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
  }
`;

const LockedItemAvatar = styled(ItemAvatar)`
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: ${({ theme, size }) => theme.spacing(-size / 2)}px;
`;

const CustomerInfoCnt = styled(Box)`
  filter: blur(${({ locked }) => (locked ? '4px' : 0)});
`;

export default ManageCustomer;
