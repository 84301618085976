import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';

import api from '../../helpers/api';
import { useGlobalState } from '../../components/GlobalState';

const DuplicateDialog = ({ workspaceId, estimate, open, onClose, onDuplicate }) => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [name, setName] = React.useState('');
  const [dstWorkspace, setDstWorkspace] = React.useState(null);

  const { globalState } = useGlobalState();

  React.useEffect(() => {
    setName(estimate ? estimate.name + ' Copy' : '');
    setDstWorkspace(globalState.client.current_workspace);
  }, [estimate, globalState.client.current_workspace]);

  const save = () => {
    setLoading(true);

    const params = {
      workspace_id: workspaceId,
      dst_workspace_id: dstWorkspace.workspace_id,
      estimate_id: estimate.estimate_id,
      name: name,
    };

    api
      .post('/estimate/duplicate', params)
      .then(response => {
        if (response.data.status.code === 0) {
          onDuplicate(response.data.result);
        } else {
          setError(response.data.statsu.message);
        }
      })
      .catch(e => {
        console.log(e);
      })
      .then(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Duplicate estimate</DialogTitle>
      <DialogContent>
        <Autocomplete
          id="workspace-select"
          disableClearable
          options={globalState.workspaces}
          value={dstWorkspace}
          onChange={(event, newValue) => setDstWorkspace(newValue)}
          getOptionLabel={option => option.name}
          renderInput={params => (
            <TextField {...params} label="Destination Workspace" variant="outlined" required />
          )}
        />

        <TextField
          autoFocus
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="duplicated_name"
          label="New estimate name"
          value={name}
          onChange={e => setName(e.target.value)}
          error={error.length > 0}
          helperText={error}
        />

        {loading && <LinearProgress />}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={e => save()} color="primary">
          Duplicate
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DuplicateDialog;
