import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import Fab from '@material-ui/core/Fab';
import Container from '@material-ui/core/Container';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';

import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import TextFieldsOutlinedIcon from '@material-ui/icons/TextFieldsOutlined';
import FolderSharedOutlinedIcon from '@material-ui/icons/FolderSharedOutlined';
import TuneOutlinedIcon from '@material-ui/icons/TuneOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import EuroOutlinedIcon from '@material-ui/icons/EuroOutlined';

import Header from 'components/Header';
import Footer from 'components/Footer';

import api from 'helpers/api';
import useAuthToken from 'helpers/useAuthToken';
import { GlobalStateContext } from 'components/GlobalState';
import PromoVideo from 'components/PromoVideo';

import Cog from './Cog';
import CogsLogo from './CogsLogo';
import EstimatingImage from 'images/estimate.jpg';
import EstimatingImage2x from 'images/estimate@2x.jpg';
import ShareImage from 'images/share.jpg';
import ShareImage2x from 'images/share@2x.jpg';

const Home = () => {
  let history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { authToken, setAuthToken } = useAuthToken();
  const [, globalStateDispatch] = React.useContext(GlobalStateContext);

  const featureRows = [
    [
      {
        icon: <GroupOutlinedIcon style={{ fontSize: 80 }} color="inherit" />,
        title: 'Team Collaboration',
        description:
          'With Estimake, your entire team can work together in the same estimate at the same time.',
      },
      {
        icon: <TextFieldsOutlinedIcon style={{ fontSize: 80 }} color="inherit" />,
        title: 'Fast Entry',
        description: 'Estimake will autocomplete tasks and hours based on your previous estimates.',
      },
      {
        icon: <FolderSharedOutlinedIcon style={{ fontSize: 80 }} color="inherit" />,
        title: 'Beautiful Exports',
        description:
          'A quote is often the first impression a customer has of your business. Quality here can put you in front of the competition.',
      },
    ],
    [
      {
        icon: <TuneOutlinedIcon style={{ fontSize: 80 }} color="inherit" />,
        title: 'Range Estimates',
        description:
          'Scarce project documentation may lead to uncertainty. In Estimake you can put a range of hours for task time.',
      },
      {
        icon: <DescriptionOutlinedIcon style={{ fontSize: 80 }} color="inherit" />,
        title: 'Task Descriptions',
        description:
          "If you're worried an estimated time may look high for your customer, you can add a description to each task explaining the complexity involved.",
      },
      {
        icon: <EuroOutlinedIcon style={{ fontSize: 80 }} color="inherit" />,
        title: 'Category Pricing',
        description:
          'Different hourly rates for Frontend, Backend, QA, or Project management activities.',
      },
    ],
  ];

  const createEstimate = () => {
    //TODO: error handling

    const onError = e => {
      enqueueSnackbar(e.toString(), { variant: 'error' });
    };

    const createGuestEstimate = client => {
      const workspace_id = client.current_workspace.workspace_id;

      globalStateDispatch({ type: 'SET_CLIENT', payload: client });

      api
        .post('/workspace/get-all')
        .then(response => {
          globalStateDispatch({
            type: 'SET_WORKSPACES',
            payload: response.data.result,
          });
        })
        .catch(onError);

      api
        .post('/estimate/create', { workspace_id: workspace_id })
        .then(response => {
          history.push('/guestimate/' + workspace_id + '/' + response.data.result.estimate_id);
        })
        .catch(onError);
    };

    if (authToken) {
      api.post('/account/client/get-profile').then(response => {
        if (response.data.status.code === 0) {
          if (response.data.result.login === 'guest') {
            createGuestEstimate(response.data.result);
          } else {
            history.push('/dash/estimates');
          }
        } else {
          setAuthToken(null);
        }
      });
    } else {
      api
        .post('/account/client/register-guest')
        .then(response => {
          setAuthToken(response.data.result.auth_token, false);

          api
            .post('/account/client/get-profile')
            .then(response => {
              createGuestEstimate(response.data.result);
            })
            .catch(onError);
        })
        .catch(onError);
    }
  };

  let cogs = React.useMemo(() => {
    let circles = [];
    let circle;
    const num_cogs = 15;
    const protection = 1000;
    let counter = 0;
    let overlapping = false;

    while (circles.length < num_cogs && counter < protection) {
      circle = {
        x: 10 + parseInt(Math.random() * 800, 10),
        y: -30 + parseInt(Math.random() * 850, 10),
        r: 15 + parseInt(Math.random() * 80, 10),
      };

      overlapping = false;

      // check that it is not overlapping with any existing circle
      // another brute force approach
      for (var i = 0; i < circles.length; i++) {
        var existing = circles[i];
        //var d = Math.hypot(circle.x-existing.x, circle.y-existing.y);
        var d = Math.sqrt(Math.pow(circle.x - existing.x, 2) + Math.pow(circle.y - existing.y, 2));

        if (d < circle.r + existing.r) {
          // They are overlapping
          overlapping = true;
          // do not add to array
          break;
        }
      }

      // add valid circles to array
      if (!overlapping) {
        circles.push(circle);
      }

      counter++;
    }

    return circles.map((circle, i) => {
      let rotation = parseInt(Math.random() * 100, 10);
      let speed = 10 + Math.random() * 70;
      let reverse = Math.random() > 0.5;

      return (
        <Cog
          key={i}
          size={circle.r * 2 + 'px'}
          bottom={circle.y - circle.r + 'px'}
          left={'calc(18% + ' + (circle.x - circle.r) + 'px)'}
          rotation={rotation}
          speed={speed}
          color="#fafafa"
          reverse={reverse}
          hole
        />
      );
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Free Software Project Estimation Tool</title>
      </Helmet>
      <Background>
        <Hidden smDown>
          {cogs}

          <Cog size="250px" bottom="-60px" left="63%" rotation="43" color="#fafafa" hole />
          <Cog size="180px" bottom="-81px" left="76%" rotation="76" color="#fafafa" hole />
        </Hidden>

        <Container maxWidth="md">
          <Header />
          <Box py={10} style={{ position: 'relative', zIndex: 2 }}>
            <Typography variant="h2" component="h1" align="center">
              Impress your customers with professional estimates
            </Typography>
            <Box pt={2}>
              <Typography variant="h5" align="center" color="textSecondary">
                Project estimation isn't always billable time. Estimake is a free tool that lets you
                and your team speed up the process.
              </Typography>
            </Box>
            <Box mt={7} mb={1} display="flex" justifyContent="center">
              <Fab variant="extended" color="primary" size="large" onClick={createEstimate}>
                Create your first estimate - it's free
              </Fab>
            </Box>
            <Box display="flex" justifyContent="center">
              <Typography variant="body1">No registration required.</Typography>
            </Box>
            <Box mt={7}>
              <PromoVideo />
            </Box>
          </Box>
        </Container>
      </Background>
      <Container maxWidth="md">
        <Box pt={15} pb={10} display="flex" flexDirection="column" alignItems="center">
          <Chip label="Features" disabled />

          <Box pt={2} display="flex">
            <Typography variant="overline" align="center" gutterBottom style={{ fontSize: '18px' }}>
              Why you'll love using Estimake
            </Typography>
          </Box>

          <Box pt={4} pb={6} width="50px">
            <Divider />
          </Box>

          {featureRows.map((features, i) => (
            <Box pb={6} key={i}>
              <Grid container justify="center" spacing={4}>
                {features.map((feature, j) => (
                  <Grid item md key={j}>
                    <Box display="flex" alignItems="center" flexDirection="column">
                      <Box color="info.main">{feature.icon}</Box>
                      <Typography variant="h6" align="center">
                        {feature.title}
                      </Typography>
                      <Typography variant="body2" align="center" color="textSecondary">
                        {feature.description}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          ))}
        </Box>
      </Container>

      <Box bgcolor="grey.200">
        <Container maxWidth="md">
          <Box py={14} display="flex" flexDirection="column" alignItems="center">
            <Typography variant="overline" align="center" gutterBottom style={{ fontSize: '18px' }}>
              What estimating looks like
            </Typography>

            <Box pt={4} pb={6} width="50px">
              <Divider />
            </Box>

            <img
              src={EstimatingImage}
              srcSet={`${EstimatingImage} 1x, ${EstimatingImage2x} 2x`}
              alt="What estimating looks like"
              loading="lazy"
            />
          </Box>
        </Container>
      </Box>

      <Container maxWidth="md">
        <Box py={14} display="flex" flexDirection="column" alignItems="center">
          <Typography variant="overline" align="center" gutterBottom style={{ fontSize: '18px' }}>
            What your customers get
          </Typography>

          <Box pt={4} pb={6} width="50px">
            <Divider />
          </Box>

          <ImagePaper elevation={12}>
            <img
              src={ShareImage}
              srcSet={`${ShareImage} 1x, ${ShareImage2x} 2x`}
              alt="What sharing looks like"
              loading="lazy"
            />
          </ImagePaper>

          <Box pt={15} display="flex">
            <Grid container justify="center" alignItems="center">
              <Grid item>
                <CogsLogo style={{ fontSize: '180px' }} />
              </Grid>

              <Grid item md={7}>
                <Box ml={8}>
                  <Typography
                    variant="overline"
                    align="center"
                    gutterBottom
                    style={{ fontSize: '18px' }}
                  >
                    Try it out now
                  </Typography>
                  <Typography variant="body2" color="textSecondary" gutterBottom>
                    Start creating estimates in minutes. It's free, no credit card required,
                    unlimited users, unlimited estimates.
                  </Typography>

                  <Box mt={2}>
                    <Grid container spacing={3}>
                      <Grid item xs={10} sm={6}>
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          onClick={createEstimate}
                        >
                          Create estimate
                        </Button>
                      </Grid>
                      <Grid item xs={10} sm={6}>
                        <Button
                          variant="outlined"
                          color="primary"
                          fullWidth
                          component="a"
                          href="https://estimake.it/share/mpfvo72hc6mquow4-6c9e69zy4dw6irocv0qv8whlyx4g"
                          target="_blank"
                        >
                          See Example export
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Footer />
      </Container>
    </>
  );
};

const Background = styled.div`
  position: relative;
  overflow: hidden;
  background-image: linear-gradient(
    150deg,
    #fdfbfb 0%,
    ${({ theme }) => theme.palette.grey[200]} 100%
  );
`;

const ImagePaper = styled(Paper)`
  position: relative;
  right: ${({ theme }) => theme.spacing(-1)}px;
`;

export default Home;
