import React from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grow from '@material-ui/core/Grow';
import { useQuery } from '../helpers/useQuery';
import hash_password from '../helpers/hash_password';

import Icon from '../components/Icon/Icon';
import { AuthPaper, Avatar, Form, Button } from '../components/Auth';
import LinearProgress from '@material-ui/core/LinearProgress';
import api from '../helpers/api';
import EndStateMessage from '../components/EndStateMessage';
import Fab from '@material-ui/core/Fab';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@material-ui/core/Box';

const PasswordReset = () => {
  let query = useQuery();

  let [token, setToken] = React.useState('');
  let [code, setCode] = React.useState('');

  React.useEffect(() => {
    setToken(query.get('token'));
    setCode(query.get('code'));
  }, [query]);

  let [new_password, setNewPassword] = React.useState('');
  let [new_password_repeat, setNewPasswordRepeat] = React.useState('');

  const [loading, setLoading] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [general_error, setGeneralError] = React.useState('');
  const [password_error, setPasswordError] = React.useState(false);
  const [password_repeat_error, setPasswordRepeatError] = React.useState(false);

  const onError = error => {
    setLoading(false);
    console.log(error);
    setGeneralError(error.toString());
  };

  const onSubmit = event => {
    event.preventDefault();

    setPasswordError(false);
    setPasswordRepeatError(false);

    let error = false;

    if (new_password.length < 8) {
      setPasswordError(true);
      error = true;
    }

    if (new_password !== new_password_repeat) {
      setPasswordRepeatError(true);
      error = true;
    }

    if (!error) {
      setLoading(true);
      setGeneralError('');

      const params = {
        token: token,
        code: code,
        password_hash: hash_password(new_password),
      };

      api
        .post('/account/client/forgotten-password-reset', params)
        .then(response => {
          setLoading(false);

          if (response.data.status.code !== 0) {
            setGeneralError(response.data.status.message);
          } else {
            setSubmitted(true);
          }
        })
        .catch(e => onError(e));
    }
  };

  let content = null;
  let maxWidth = 'xs';

  if (submitted) {
    content = (
      <EndStateMessage
        title="Password Recovered"
        message="You have successfully set a new password to your account."
      >
        <Box py={2}>
          <Fab to="/login" variant="extended" color="primary" component={RouterLink}>
            Log In
          </Fab>
        </Box>
      </EndStateMessage>
    );
    maxWidth = 'lg';
  } else {
    content = (
      <AuthPaper>
        <Avatar>
          <Icon />
        </Avatar>

        <Typography component="h1" variant="h5">
          Reset password
        </Typography>

        <Form noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="New password"
            type="password"
            id="password"
            autoComplete="current-password"
            autoFocus
            value={new_password}
            onChange={e => setNewPassword(e.target.value)}
            error={password_error}
            helperText={password_error && 'Password must be at least 8 characters long'}
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="repeat_password"
            label="Repeat password"
            type="password"
            id="repeat_password"
            autoComplete="repeat-password"
            value={new_password_repeat}
            onChange={e => setNewPasswordRepeat(e.target.value)}
            error={password_repeat_error}
            helperText={password_repeat_error && 'The entered values do not match'}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={event => onSubmit(event)}
          >
            Update password
          </Button>

          {loading && <LinearProgress />}
          {general_error && (
            <Typography color="error" align="center">
              {general_error}
            </Typography>
          )}
        </Form>
      </AuthPaper>
    );
  }

  return (
    <Grow in={true}>
      <Container maxWidth={maxWidth}>{content}</Container>
    </Grow>
  );
};

export default PasswordReset;
