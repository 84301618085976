import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';

import api from '../helpers/api';
import { GlobalStateContext } from './GlobalState';
import ItemAvatar from './ItemAvatar';
import HiddenImageInput from './HiddenImageInput';

const WorkspaceAvatarEdit = ({ workspace }) => {
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();

  const [, dispatchGlobalState] = React.useContext(GlobalStateContext);

  const [avatarLoading, setAvatarLoading] = React.useState(false);

  const removeLogo = () => {
    let options = {
      description: 'Are you sure you want to remove company logo? This cannot be undone.',
      confirmationText: 'Remove logo',
      dialogProps: {
        maxWidth: 'xs',
      },
    };

    confirm(options)
      .then(() => {
        setAvatarLoading(true);

        api
          .post('/workspace/delete-avatar', {
            workspace_id: workspace.workspace_id,
          })
          .then(response => {
            workspace.image = null;
            dispatchGlobalState({
              type: 'UPDATE_WORKSPACE',
              payload: workspace,
            });
            enqueueSnackbar('Workspace logo removed', { variant: 'info' });
          })
          .catch(e => {
            console.log(e);
          })
          .then(() => {
            setAvatarLoading(false);
          });
      })
      .catch(e => {});
  };

  const handleFileUpload = ({ target }) => {
    setAvatarLoading(true);

    const fileReader = new FileReader();

    fileReader.onload = e => {
      var base64result = e.target.result.split(',')[1];

      api
        .post('/workspace/update-avatar', {
          workspace_id: workspace.workspace_id,
          data: base64result,
        })
        .then(response => {
          workspace.image = response.data.image;
          dispatchGlobalState({ type: 'UPDATE_WORKSPACE', payload: workspace });
          enqueueSnackbar('Workspace logo updated', { variant: 'info' });
        })
        .catch(e => {
          console.log(e);
        })
        .then(() => {
          setAvatarLoading(false);
        });
    };

    fileReader.readAsDataURL(target.files[0]);
  };

  return (
    <Grid container alignItems="center" spacing={3}>
      <Grid item>
        {workspace && (
          <ItemAvatar
            text={workspace.name}
            resource={workspace.image}
            size={8}
            loading={avatarLoading}
          />
        )}
      </Grid>
      <Grid item>
        <HiddenImageInput onChange={handleFileUpload} id="contained-button-file" />
        <label htmlFor="contained-button-file">
          <Button variant="contained" color="primary" component="span">
            Upload
          </Button>
        </label>
      </Grid>
      <Grid item>
        <Button variant="outlined" onClick={removeLogo}>
          Remove
        </Button>
      </Grid>
    </Grid>
  );
};

export default WorkspaceAvatarEdit;
