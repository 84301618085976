import React from 'react';

const initialState = {
  client: null,
  workspaces: [],
};

const Reducer = (state, action) => {
  let workspaces;

  switch (action.type) {
    case 'SET_CLIENT':
      return {
        ...state,
        client: action.payload,
      };
    case 'SET_WORKSPACES':
      return {
        ...state,
        workspaces: action.payload,
      };
    case 'SET_CURRENT_WORKSPACE':
      workspaces = state.workspaces.map(workspace => {
        workspace.is_current = workspace.workspace_id === action.payload.workspace_id;
        return workspace;
      });

      return {
        ...state,
        client: {
          ...state.client,
          current_workspace: action.payload,
        },
        workspaces: workspaces,
      };
    case 'UPDATE_WORKSPACE':
      workspaces = state.workspaces.map(workspace => {
        if (workspace.workspace_id === action.payload.workspace_id) {
          return { ...workspace, ...action.payload };
        }

        return workspace;
      });

      let current_workspace = state.client.current_workspace;

      if (current_workspace.workspace_id === action.payload.workspace_id) {
        current_workspace = { ...current_workspace, ...action.payload };
      }

      return {
        ...state,
        client: {
          ...state.client,
          current_workspace: current_workspace,
        },
        workspaces: workspaces,
      };
    case 'UPDATE_AVATAR':
      return {
        ...state,
        client: {
          ...state.client,
          image: action.payload,
        },
      };
    case 'LOGOUT':
      return { ...initialState };
    default:
      return state;
  }
};

export const GlobalStateContext = React.createContext(initialState);

const GlobalState = ({ children }) => {
  const [state, dispatch] = React.useReducer(Reducer, initialState);

  return (
    <GlobalStateContext.Provider value={[state, dispatch]}>{children}</GlobalStateContext.Provider>
  );
};

export const useGlobalState = () => {
  const [globalState, dispatchGlobalState] = React.useContext(GlobalStateContext);

  return {
    globalState: globalState,
    dispatchGlobalState: dispatchGlobalState,
  };
};

export default GlobalState;
