import React from 'react';
import InputBase from '@material-ui/core/InputBase';
import styled, { css } from 'styled-components';

const EditableField = React.forwardRef(({ value, loading, onSave, ...props }, ref) => {
  const [saving, setSaving] = React.useState(false);
  const [lastValue, setLastValue] = React.useState(value || '');
  const [inputValue, setInputValue] = React.useState(value || '');
  const [, setSaveError] = React.useState();

  React.useEffect(() => {
    setInputValue(value || '');
  }, [value]);

  return (
    <StyledInputBase
      ref={ref}
      value={inputValue}
      onChange={e => setInputValue(e.target.value)}
      disabled={loading || saving}
      {...props}
      onBlur={async e => {
        props.onBlur && props.onBlur(e);

        const val = e.target.value;
        if (val !== lastValue) {
          setSaving(true);
          try {
            onSave && (await onSave(val));
            setSaving(false);
            setLastValue(val);
          } catch (err) {
            setSaveError('Error Message');
          }
        }
      }}
    />
  );
});

export const StyledInputBase = styled(InputBase)`
  & input {
    padding: 6px 5px 6px 7px;
    border: 1px solid transparent;
    transition: border 0.2s;

    ${props =>
      props.$textAlign &&
      css`
        text-align: ${props.$textAlign};
      `}

    ${props =>
      props.$fontSize &&
      css`
        font-size: ${props.$fontSize};
      `}

        ${props =>
      props.$error &&
      css`
        border: 1px solid ${({ theme }) => theme.palette.secondary.main};
      `}
  }

  ${props =>
    !props.$error &&
    css`
      & input:hover {
        border: 1px solid ${({ theme }) => theme.palette.divider};
      }

      & input:focus {
        border: 1px solid ${({ theme }) => theme.palette.primary.main};
      }
    `}
`;

export default EditableField;
