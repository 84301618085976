import React from 'react';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Grow from '@material-ui/core/Grow';

import api from '../../helpers/api';
import useAuthToken from '../../helpers/useAuthToken';
import ItemAvatar from '../ItemAvatar';
import { useGlobalState } from '../GlobalState';
import { useEstimateStore } from './EstimateStore';

const WS_URL = process.env.REACT_APP_WS_URL;

const WebsocketContext = React.createContext(null);

const RealTimeProvider = ({ children, workspaceId, estimateId }) => {
  const { authToken } = useAuthToken();
  const { dispatchGlobalState } = useGlobalState();
  const { dispatch } = useEstimateStore();
  const [reconnect, setReconnect] = React.useState(0);

  const [open, setOpen] = React.useState(false);

  const ws = React.useRef(null);

  React.useEffect(() => {
    let isMounted = true;

    ws.current = new WebSocket(WS_URL + '/estimate');

    ws.current.onopen = () => {
      console.log('Websocket opened');

      setOpen(true);

      websocketWrite({
        action: 'join',
        payload: {
          estimate_id: parseInt(estimateId, 10),
          auth_token: authToken,
        },
      });
    };

    ws.current.onclose = () => {
      isMounted && setOpen(false);
      console.log('Websocket closed');
    };

    ws.current.onerror = function (error) {
      console.error(error);
    };

    ws.current.onmessage = e => {
      const message = JSON.parse(e.data);

      //console.log("onmessage", message);

      switch (message.type) {
        case 'SET_ONLINE_MEMBERS':
          dispatch(message);
          break;
        case 'SET_LOCKED_ITEMS':
          dispatch(message);
          break;
        case 'UPDATED_ITEM':
          dispatch({
            type: 'UPDATE_ITEM',
            payload: JSON.parse(message.payload),
          });
          break;
        case 'DELETED_ITEM':
          dispatch({ type: 'REMOVE_ITEM', payload: message.payload });
          break;
        case 'UPDATED_CATEGORY':
          dispatch({
            type: 'UPDATE_CATEGORY',
            payload: JSON.parse(message.payload),
          });
          break;
        case 'DELETED_CATEGORY':
          dispatch({ type: 'REMOVE_CATEGORY', payload: message.payload });
          break;
        case 'SET_LOCKED_SECTIONS':
          dispatch(message);
          break;
        case 'WORKSPACE_UPDATED':
          api
            .post('/workspace/get', {
              workspace_id: workspaceId,
              expanded: true,
            })
            .then(response => {
              dispatchGlobalState({
                type: 'UPDATE_WORKSPACE',
                payload: response.data.result,
              });
            });
          break;
        case 'UPDATED_ESTIMATE':
          dispatch({
            type: 'UPDATE_ESTIMATE',
            payload: JSON.parse(message.payload),
          });
          break;
        default:
          console.warn('Unrecognised action from ws', message);
      }
    };

    return () => {
      isMounted = false;
      ws.current.close();
    };
  }, [estimateId, workspaceId, authToken, dispatch, reconnect, dispatchGlobalState]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (ws.current.readyState === 3) {
        // If WebSocket is closed
        console.info('setInterval retry WebSocket connection');
        setReconnect(reconnect + 1);
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [reconnect]);

  const websocketWrite = msg => {
    //console.log('send', msg);

    if (ws.current.readyState === 1) {
      // OPEN
      ws.current.send(JSON.stringify(msg));
    }
  };

  const ctx = {
    send: websocketWrite,
    open: open,
  };

  return <WebsocketContext.Provider value={ctx}>{children}</WebsocketContext.Provider>;
};

export const OnlineMembers = () => {
  const { globalState } = useGlobalState();
  const { estimate } = useEstimateStore();

  const avatars =
    estimate.online_clients &&
    estimate.online_clients.map(client_id => {
      let member = globalState.client.current_workspace.members.find(
        member => member.client_id === client_id
      );

      return (
        member && (
          <Grow in={true} key={client_id}>
            <ItemAvatar text={member.client.full_name} resource={member.client.image} online />
          </Grow>
        )
      );
    });

  return <AvatarGroup max={6}>{avatars}</AvatarGroup>;
};

//TODO: rename this to useWebsocket
export const useWebsocketConn = () => {
  return React.useContext(WebsocketContext);
};

export default RealTimeProvider;
