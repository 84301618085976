import React from 'react';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { useSnackbar } from 'notistack';

import api from '../../helpers/api';
import { StyledInputBase } from './ManageCustomer';

const filter = createFilterOptions();

const ManageProject = ({ workspaceId, customerId, projectId, onChangeProject }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [projects, setProjects] = React.useState([]);
  const [project, setProject] = React.useState(null);

  React.useEffect(() => {
    api
      .post('/project/get-all', {
        workspace_id: workspaceId,
        customer_id: customerId,
      })
      .then(response => {
        if (response.data.status.code === 0) {
          setProjects(response.data.result);

          const project = response.data.result.find(project => project.project_id === projectId);

          if (project) {
            setProject(project);
          } else {
            setProject(null);
          }
        } else {
          //handle error
        }
      })
      .catch(errors => {
        console.log(errors);
      })
      .then(() => {});
  }, [customerId, workspaceId, projectId]);

  const createProject = name => {
    setProject({
      name: name,
    });

    const params = {
      workspace_id: workspaceId,
      customer_id: customerId,
      check_archive: false,
      name: name,
    };

    api
      .post('/project/create', params)
      .then(response => {
        if (response.data.status.code === 0) {
          setProject(response.data.result);
          onChangeProject(response.data.result);
          enqueueSnackbar('Added project "' + response.data.result.name + '"', {
            variant: 'success',
          });
        } else {
          // Handle error
        }
      })
      .catch(errors => {
        console.log(errors);
      })
      .then(() => {});
  };

  return (
    <Autocomplete
      value={project}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          createProject(newValue);
        } else if (newValue && newValue.inputValue) {
          createProject(newValue.inputValue);
        } else {
          setProject(newValue);
          onChangeProject(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== '') {
          filtered.push({
            inputValue: params.inputValue,
            name: `Create new project "${params.inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      options={projects.filter(project => project.is_archived === false)}
      getOptionLabel={option => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }

        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }

        // Regular option
        return option.name;
      }}
      renderOption={option => option.name}
      freeSolo
      renderInput={params => (
        <div ref={params.InputProps.ref}>
          <StyledInputBase fullWidth placeholder="Type project name" {...params.inputProps} />
        </div>
      )}
    />
  );
};

export default ManageProject;
