import React from 'react';
import { Route } from 'react-router-dom';

import WebsiteLayout from './WebsiteLayout';

const WebsiteRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        <WebsiteLayout>
          <Component {...props} />
        </WebsiteLayout>
      )}
    />
  );
};

export default WebsiteRoute;
