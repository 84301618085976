import React from 'react';
import Box from '@material-ui/core/Box';
import Grow from '@material-ui/core/Grow';
import Fab from '@material-ui/core/Fab';

import { Link as RouterLink } from 'react-router-dom';

import useAuthToken from './../helpers/useAuthToken';
import EndStateMessage from '../components/EndStateMessage';

const EmailVerifySuccess = () => {
  const { authToken } = useAuthToken();

  return (
    <Grow in={true}>
      <EndStateMessage
        title="Verification success"
        message="Thank you for confirming your email address with us. You can now access the full set of Estimake features."
      >
        <Box py={2}>
          {authToken ? (
            <Fab to="/dash/estimates" variant="extended" color="primary" component={RouterLink}>
              Go to Estimates
            </Fab>
          ) : (
            <Fab to="/login" variant="extended" color="primary" component={RouterLink}>
              Log In
            </Fab>
          )}
        </Box>
      </EndStateMessage>
    </Grow>
  );
};

export default EmailVerifySuccess;
