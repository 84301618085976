import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import NoSsr from '@material-ui/core/NoSsr';
import theme from './helpers/theme';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { ConfirmProvider } from 'material-ui-confirm';
import { SnackbarProvider } from 'notistack';

import 'fontsource-roboto';
import 'fontsource-roboto/500.css';
import 'fontsource-roboto/300.css';
import 'fontsource-roboto/700.css';

ReactDOM.render(
  <NoSsr>
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider maxSnack={1} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
          <ConfirmProvider
            defaultOptions={{
              title: 'Please confirm',
              confirmationButtonProps: { autoFocus: true },
            }}
          >
            <App />
          </ConfirmProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </MuiThemeProvider>
  </NoSsr>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
