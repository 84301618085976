import React from 'react';
import Container from '@material-ui/core/Container';
import { useParams } from 'react-router-dom';

import { GlobalStateContext } from '../../components/GlobalState';
import RealTimeProvider from '../../components/Estimate/RealTimeProvider';
import ManageEsimate from '../../components/Estimate/ManageEstimate';
import EstimateStore from '../../components/Estimate/EstimateStore';

const Manage = () => {
  const { estimateId } = useParams();
  const [globalState] = React.useContext(GlobalStateContext);

  const workspaceId = globalState.client.current_workspace.workspace_id;

  return (
    <Container maxWidth="md">
      <EstimateStore>
        <RealTimeProvider workspaceId={workspaceId} estimateId={estimateId}>
          <ManageEsimate workspaceId={workspaceId} estimateId={estimateId} />
        </RealTimeProvider>
      </EstimateStore>
    </Container>
  );
};

export default Manage;
