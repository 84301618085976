import React from 'react';
import PropTypes from 'prop-types';

const EstimatedTime = ({ from, to }) => {
  if (Math.abs(to - from) < 0.0001) {
    return <>{from}h</>;
  } else {
    return (
      <>
        {from}h - {to}h
      </>
    );
  }
};

EstimatedTime.propTypes = {
  from: PropTypes.number.isRequired,
  to: PropTypes.number.isRequired,
};

export default EstimatedTime;
