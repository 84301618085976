import PropTypes from 'prop-types';

import { useCurrencyFormatter } from '../hooks/currencies';

const CurrencyAmount = ({ amount, currency_id }) => {
  const formatter = useCurrencyFormatter(currency_id);

  return formatter.format(amount);
};

CurrencyAmount.defaultProps = {
  amount: 0,
  currency_id: null,
};

CurrencyAmount.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currency_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default CurrencyAmount;
