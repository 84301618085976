import React from 'react';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Collapse from '@material-ui/core/Collapse';
import RightIcon from '@material-ui/icons/KeyboardArrowRight';
import DownIcon from '@material-ui/icons/KeyboardArrowDown';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import styled from 'styled-components';
import { useConfirm } from 'material-ui-confirm';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import api from '../../helpers/api';
import { estimatedTimeFormat } from '../../helpers/datetime';
import CurrencyAmount from '../CurrencyAmount';
import ManageCategoryRate from './ManageCategoryRate';
import CategoryItem from './CategoryItem';
import EditableField from './EditableField';
import { useEstimateStore } from './EstimateStore';
import { useWebsocketConn } from './RealTimeProvider';

const Category = ({ category, dragHandleProps }) => {
  const confirm = useConfirm();

  const ws = useWebsocketConn();

  const { estimate, dispatch } = useEstimateStore();

  const [deleteLoading, setDeleteLoading] = React.useState(false);
  const [expanded, setExpanded] = React.useState(true);
  const [itemsExpanded, setItemsExpanded] = React.useState(true);

  const onSaveName = async new_name => {
    const params = {
      ...category,
      workspace_id: estimate.workspace_id,
      name: new_name,
    };

    const response = await api.post('/category/update', params);

    ws.send({
      action: 'updatedCategory',
      payload: JSON.stringify(response.data.result),
    });
  };

  const onDelete = () => {
    setDeleteLoading(true);

    let options = {
      description: 'Are you sure you want to delete this category and all of the tasks within?',
      confirmationText: 'Yes, delete it',
    };

    confirm(options)
      .then(() => {
        const params = {
          workspace_id: estimate.workspace_id,
          estimate_category_id: category.estimate_category_id,
          check_items: false,
        };

        api
          .post('/category/delete', params)
          .then(response => {
            if (response.data.status.code === 0) {
              setExpanded(false);

              ws.send({
                action: 'deletedCategory',
                payload: category.estimate_category_id,
              });
            }
          })
          .catch(e => {
            console.log(e);
          })
          .then(() => {
            setDeleteLoading(false);
          });
      })
      .catch(e => {
        setDeleteLoading(false);
      });
  };

  const toggleItemsVisible = () => {
    setItemsExpanded(!itemsExpanded);
  };

  const dispatchRemoveCategory = () => {
    dispatch({
      type: 'REMOVE_CATEGORY',
      payload: category.estimate_category_id,
    });
  };

  const onSaveRate = async (rate, currency_id) => {
    if (isNaN(rate) || parseFloat(rate) === 0 || rate === '') rate = null;

    const params = {
      ...category,
      workspace_id: estimate.workspace_id,
      rate: rate,
      currency_id: currency_id,
    };

    let response = await api.post('/category/update', params);

    if (response.data.status.code === 0) {
      dispatch({
        type: 'SET_CATEGORY_RATE',
        payload: {
          rate: rate,
          estimate_category_id: category.estimate_category_id,
        },
      });

      ws.send({
        action: 'updatedCategory',
        payload: JSON.stringify({
          rate: rate,
          estimate_category_id: category.estimate_category_id,
        }),
      });
    } else {
      throw response.data.status.message;
    }
  };

  const items = category ? category.items : estimate.items;

  let hours_from = items.reduce((accumulator, item) => {
    return (
      accumulator +
      (item.estimate_time_type === 'days'
        ? item.estimate_time_from * 8
        : item.estimate_time_from
        ? item.estimate_time_from
        : 0)
    );
  }, 0);

  let hours_to = items.reduce((accumulator, item) => {
    return (
      accumulator +
      (item.estimate_time_type === 'days'
        ? item.estimate_time_to * 8
        : item.estimate_time_to
        ? item.estimate_time_to
        : 0)
    );
  }, 0);

  return (
    <Collapse in={expanded} onExited={dispatchRemoveCategory}>
      <Paper variant="outlined">
        <CategoryHeader display="flex" justifyContent="space-between" alignItems="center">
          <Box p={1} display="flex" alignItems="center" width={1 / 2}>
            {category && (
              <IconButton size="small" {...dragHandleProps}>
                <DragIndicatorIcon color="primary" />
              </IconButton>
            )}
            <IconButton onClick={toggleItemsVisible} size="small">
              {itemsExpanded ? <DownIcon color="primary" /> : <RightIcon color="primary" />}
            </IconButton>
            {category ? (
              <EditableField value={category.name} onSave={onSaveName} fullWidth />
            ) : (
              <Box pl={1}>
                <Typography variant="body1">Uncategorised tasks</Typography>
              </Box>
            )}
          </Box>
          {category && (
            <Box p={1}>
              <Tooltip title="Delete category">
                <IconButton size="small" onClick={onDelete} color="primary">
                  {deleteLoading ? <CircularProgress size="1.125rem" /> : <DeleteIcon />}
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </CategoryHeader>

        <Divider />

        <Collapse in={itemsExpanded}>
          <Droppable
            droppableId={'dropable_' + (category ? category.estimate_category_id : 'uncategorised')}
            type="CATEGORY_ITEM"
          >
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {items.map((item, index) => (
                  <Draggable
                    key={item.estimate_item_id}
                    draggableId={'draggable-' + item.estimate_item_id}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.draggableProps}>
                        <CategoryItem
                          category={category}
                          item={item}
                          dragHandleProps={provided.dragHandleProps}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>

          <CategoryItem category={category} item={null} />
        </Collapse>

        <CategoryHeader p={1} textAlign="right" display="flex" justifyContent="space-between">
          <Box>
            {category && estimate.currency_id && (
              <ManageCategoryRate
                defaultRate={category.rate}
                currencyId={estimate.currency_id}
                onSave={onSaveRate}
              />
            )}
          </Box>

          <Box display="flex" alignItems="center">
            <Typography variant="subtitle2" component="span">
              {estimatedTimeFormat.format(hours_from)}h
              {hours_from !== hours_to && (
                <>
                  {' - '}
                  {estimatedTimeFormat.format(hours_to)}h
                </>
              )}
              {estimate.currency_id && (
                <>
                  {' / '}
                  <CurrencyAmount
                    amount={
                      (category && category.rate !== null ? category.rate : estimate.rate) *
                      hours_from
                    }
                    currency_id={estimate.currency_id}
                  />
                  {hours_from !== hours_to && (
                    <>
                      {' - '}
                      <CurrencyAmount
                        amount={
                          (category && category.rate !== null ? category.rate : estimate.rate) *
                          hours_to
                        }
                        currency_id={estimate.currency_id}
                      />
                    </>
                  )}
                </>
              )}
            </Typography>
          </Box>
        </CategoryHeader>
      </Paper>
    </Collapse>
  );
};

const CategoryHeader = styled(Box)`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.grey[50]};
`;

export default Category;
