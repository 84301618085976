import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import DescriptionPopulatedIcon from '@material-ui/icons/DescriptionOutlined';
import DescriptionEmptyIcon from '@material-ui/icons/InsertDriveFileOutlined';

import api from '../../helpers/api';
import TaskDescription from '../TaskDescription';
import { useEstimateStore } from './EstimateStore';
import TooltipIconButton from './TooltipIconButton';
import { useWebsocketConn } from './RealTimeProvider';

const ItemDescription = ({ item, disabled }) => {
  const { estimate, dispatch } = useEstimateStore();

  const ws = useWebsocketConn();

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [description, setDescription] = React.useState('');

  React.useEffect(() => {
    setDescription(item && item.description ? item.description : '');
  }, [item]);

  const onCloseDialog = () => {
    ws.send({ action: 'unlockItem' });
    setOpen(false);
  };

  const onOpenDialog = () => {
    setOpen(true);

    ws.send({
      action: 'requestItemLock',
      payload: item.estimate_item_id,
    });
  };

  const onSave = async () => {
    setError('');
    setLoading(true);

    const params = {
      ...item,
      workspace_id: estimate.workspace_id,
      description: description,
    };

    try {
      const response = await api.post('/item/update', params);

      if (response.data.status.code !== 0) {
        setError(response.data.status.message);
      } else {
        onCloseDialog();
        dispatch({ type: 'UPDATE_ITEM', payload: response.data.result });

        ws.send({
          action: 'updatedItem',
          payload: JSON.stringify(response.data.result),
        });
      }
    } catch (error) {
      console.error(error);
      setError(error.toString());
    }

    setLoading(false);
  };

  let tooltip = item && item.description ? item.description : 'Task description (empty)';

  return (
    <>
      <TooltipIconButton
        size="small"
        title={<TaskDescription description={tooltip} />}
        disabled={!item || disabled}
        onClick={onOpenDialog}
        color="primary"
      >
        {item && item.description ? <DescriptionPopulatedIcon /> : <DescriptionEmptyIcon />}
      </TooltipIconButton>

      <Dialog open={open} onClose={onCloseDialog} fullWidth>
        <DialogTitle>Task Description</DialogTitle>
        <DialogContent>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            multiline
            rows={8}
            name="item_description"
            label="Description / Notes"
            value={description}
            onChange={e => setDescription(e.target.value)}
            error={error.length > 0}
            helperText={error}
          />
          {loading && <LinearProgress />}
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseDialog}>Cancel</Button>
          <Button onClick={onSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ItemDescription;
