import React from 'react';
import styled from 'styled-components';

const HiddenImageInput = params => {
  return <HiddenInput accept="image/*" type="file" {...params} />;
};

const HiddenInput = styled.input`
  display: none;
`;

export default HiddenImageInput;
