import React from 'react';
import styled from 'styled-components';
import icon from './Icon.png';

const Icon = () => <IconImage src={icon} alt="Estimake icon" />;

const IconImage = styled.img`
  width: 100%;
`;

export default Icon;
