import React from 'react';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import { Link as RouterLink } from 'react-router-dom';

import Logo from './Logo/Logo';
import HeaderLogin from './HeaderLogin';

const Header = () => {
  return (
    <Box display="flex" alignItems="center" component={HeaderContainer}>
      <Box flexGrow={1}>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
      </Box>
      <HeaderLogin />
    </Box>
  );
};

const HeaderContainer = styled.nav`
  height: ${({ theme }) => theme.spacing(8)}px;
  padding-top: ${({ theme }) => theme.spacing(1)}px;
  box-sizing: border-box;
`;

export default Header;
