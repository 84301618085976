import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ArchiveIcon from '@material-ui/icons/ArchiveOutlined';
import UnarchiveIcon from '@material-ui/icons/UnarchiveOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import CopyIcon from '@material-ui/icons/FileCopyOutlined';
import { useConfirm } from 'material-ui-confirm';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import HeaderForm from '../../components/HeaderForm';
import AddButton from '../../components/AddButton';
import DataTable from '../../components/DataTable';
import DateLabel from '../../components/DateLabel';
import EstimatedTime from '../../components/EstimatedTime';
import ItemAvatar from '../../components/ItemAvatar';
import { GlobalStateContext } from '../../components/GlobalState';
import api from '../../helpers/api';
import DuplicateDialog from './DuplicateDialog';

const List = () => {
  const { enqueueSnackbar } = useSnackbar();
  let history = useHistory();
  let { url } = useRouteMatch();

  const confirm = useConfirm();

  const [globalState] = React.useContext(GlobalStateContext);

  const [customers, setCustomers] = React.useState([]);
  const [projects, setProjects] = React.useState([]);

  // filters
  const [searchTerm, setSearchTerm] = React.useState('');
  const [searchCustomer, setSearchCustomer] = React.useState(null);
  const [searchProject, setSearchProject] = React.useState(null);
  const [archived, setArchived] = React.useState(false);

  const [estimates, setEstimates] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const [duplicateOpen, setDuplicateOpen] = React.useState(false);
  const [duplicateEstimate, setDuplicateEstimate] = React.useState(null);

  React.useEffect(() => {
    api
      .post('/customer/get-all', {
        workspace_id: globalState.client.current_workspace.workspace_id,
      })
      .then(response => {
        setCustomers(response.data.result);
      })
      .catch(e => {
        console.log(e);
      });

    api
      .post('/project/get-all', {
        workspace_id: globalState.client.current_workspace.workspace_id,
      })
      .then(response => {
        setProjects(response.data.result);
      })
      .catch(e => {
        console.log(e);
      });
  }, [globalState]);

  const reload = () => {
    setLoading(true);

    api
      .post('/estimate/get-all', {
        workspace_id: globalState.client.current_workspace.workspace_id,
      })
      .then(response => {
        setEstimates(response.data.result);
      })
      .catch(e => {
        console.log(e);
      })
      .then(() => {
        setLoading(false);
      });
  };

  React.useEffect(reload, [globalState, history.length]);

  const onAddNew = () => {
    setLoading(true);

    const params = {
      workspace_id: globalState.client.current_workspace.workspace_id,
    };

    api
      .post('/estimate/create', params)
      .then(response => {
        if (response.data.status.code !== 0) {
          enqueueSnackbar(response.data.status.message, { variant: 'error' });
        } else {
          reload();
          history.push(url + '/manage/' + response.data.result.estimate_id);
        }
      })
      .catch(e => {
        console.log(e);
      })
      .then(() => {
        setLoading(false);
      });
  };

  const toggleArchive = estimate => {
    let options = {
      description: estimate.is_archived
        ? 'Are you sure you want to restore this estimate?'
        : 'Are you sure you want to archive this estimate?',
      confirmationText: estimate.is_archived ? 'Unarchive' : 'Archive',
    };

    confirm(options)
      .then(() => {
        setLoading(true);

        const params = {
          workspace_id: globalState.client.current_workspace.workspace_id,
          estimate_id: estimate.estimate_id,
        };

        const url = estimate.is_archived ? '/estimate/unarchive' : '/estimate/archive';

        api
          .post(url, params)
          .then(response => {
            if (response.data.status.code === 0) {
              reload();
              enqueueSnackbar(estimate.is_archived ? 'Estimate restored' : 'Estimate archived', {
                variant: 'info',
              });
            }
          })
          .catch(e => {
            reload();
          })
          .then(() => {
            setLoading(false);
          });
      })
      .catch(e => {});
  };

  const onDelete = estimate => {
    let options = {
      description:
        'Are you sure you want to delete this estimate? This operation cannot be undone!',
      confirmationText: 'Delete',
    };

    confirm(options)
      .then(() => {
        setLoading(true);

        const params = {
          workspace_id: globalState.client.current_workspace.workspace_id,
          estimate_id: estimate.estimate_id,
        };

        api
          .post('/estimate/delete', params)
          .then(response => {
            if (response.data.status.code === 0) {
              reload();
              enqueueSnackbar('Estimate deleted', { variant: 'info' });
            }
          })
          .catch(e => {
            reload();
          })
          .then(() => {
            setLoading(false);
          });
      })
      .catch(e => {});
  };

  const apply_filter = estimate => {
    if (searchCustomer && estimate.customer_id !== searchCustomer.customer_id) {
      return false;
    }

    if (searchProject && estimate.project_id !== searchProject.project_id) {
      return false;
    }

    return (
      estimate.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      estimate.is_archived === archived
    );
  };

  let actions = [
    {
      tooltip: archived ? 'Unarchive' : 'Archive',
      icon: () => (archived ? <UnarchiveIcon /> : <ArchiveIcon />),
      onClick: (evt, estimate) => toggleArchive(estimate),
    },
  ];

  if (archived) {
    actions.push({
      tooltip: 'Delete',
      icon: () => <DeleteIcon />,
      onClick: (evt, estimate) => onDelete(estimate),
    });
  } else {
    actions.push({
      tooltip: 'Duplicate',
      icon: () => <CopyIcon />,
      onClick: (evt, estimate) => {
        setDuplicateOpen(true);
        setDuplicateEstimate(estimate);
      },
    });
  }

  const onRowClick = !archived
    ? (event, rowData, togglePanel) => {
        history.push(url + '/manage/' + rowData.estimate_id);
      }
    : undefined;

  return (
    <Grid container spacing={3} direction="column">
      <Grid item container spacing={3} justify="space-between" alignItems="center">
        <Grid item xs={8}>
          <HeaderForm>
            <Autocomplete
              id="customers-select"
              size="small"
              value={searchCustomer}
              onChange={(event, newValue) => setSearchCustomer(newValue)}
              options={customers.filter(customer => customer.is_archived === false)}
              getOptionLabel={option => option.name}
              renderInput={params => <TextField {...params} label="Customer" variant="outlined" />}
            />

            <Autocomplete
              id="projects-select"
              size="small"
              value={searchProject}
              onChange={(event, newValue) => setSearchProject(newValue)}
              options={projects.filter(project => {
                if (
                  (searchCustomer && project.customer_id !== searchCustomer.customer_id) ||
                  project.is_archived
                ) {
                  return false;
                }

                return true;
              })}
              getOptionLabel={option => option.name}
              renderInput={params => <TextField {...params} label="Project" variant="outlined" />}
            />

            <TextField
              size="small"
              label="Search"
              variant="outlined"
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
            />

            <FormControlLabel
              control={
                <Checkbox checked={archived} onChange={e => setArchived(e.target.checked)} />
              }
              label="Show archived"
            />
          </HeaderForm>
        </Grid>
        <Grid container item xs={2} justify="flex-end" onClick={onAddNew}>
          <AddButton title="Create estimate" />
        </Grid>
      </Grid>
      <Grid item>
        <DataTable
          isLoading={loading}
          columns={[
            { title: 'Name', field: 'name' },
            {
              title: 'Created by',
              field: 'created_by',
              render: rowData => <CreatedBy row={rowData} globalState={globalState} />,
            },
            {
              title: 'Customer',
              field: 'customer_name',
              render: rowData => {
                const customer = customers.find(
                  customer => customer.customer_id === rowData.customer_id
                );
                return customer ? customer.name : '-';
              },
            },
            {
              title: 'Project',
              field: 'project_name',
              render: rowData => {
                const project = projects.find(project => project.project_id === rowData.project_id);
                return project ? project.name : '-';
              },
            },
            {
              title: 'Hours',
              field: 'hours',
              render: rowData => {
                let from =
                  rowData.estimated_duration.hours.from + rowData.estimated_duration.misc.from;
                let to = rowData.estimated_duration.hours.to + rowData.estimated_duration.misc.to;
                from += rowData.estimated_duration.days.from * 8;
                to += rowData.estimated_duration.days.to * 8;

                return <EstimatedTime from={Math.round(from)} to={Math.round(to)} />;
              },
            },
            {
              title: 'Created',
              field: 'created',
              render: rowData => <DateLabel date={rowData.created} />,
            },
          ]}
          data={estimates.filter(apply_filter)}
          actions={actions}
          title="Estimates"
          onRowClick={onRowClick}
        />
      </Grid>
      <Grid item></Grid>

      <DuplicateDialog
        workspaceId={globalState.client.current_workspace.workspace_id}
        estimate={duplicateEstimate}
        open={duplicateOpen}
        onClose={() => setDuplicateOpen(false)}
        onDuplicate={estimate => {
          setDuplicateOpen(false);
          reload();
          enqueueSnackbar('Estimate copied', { variant: 'info' });
        }}
      />
    </Grid>
  );
};

const CreatedBy = ({ row, globalState }) => {
  let avatar_text = null;
  let avatar_resource = null;

  const member = globalState.client.current_workspace.members.find(
    member => row.creator_client_id === member.client_id
  );

  if (member) {
    avatar_text = member.client.full_name;
    avatar_resource = member.client.image;
  }

  return (
    <Grid container alignItems="center">
      <ItemAvatar text={avatar_text} resource={avatar_resource} />
      <Box mx={1}>
        <Typography>{avatar_text}</Typography>
      </Box>
    </Grid>
  );
};

export default List;
