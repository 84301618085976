import React from 'react';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Fab from '@material-ui/core/Fab';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import ShareIcon from '@material-ui/icons/ShareOutlined';
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';
import styled from 'styled-components';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';

import api from '../../helpers/api';
import CopyLink from '../CopyLink';

const ShareDialog = ({ workspaceId, estimate, dispatch }) => {
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');

  const onCloseDialog = () => {
    setOpen(false);
  };

  const onOpenDialog = () => {
    setOpen(true);
  };

  const createShareLink = enable => {
    setError('');
    setLoading(true);

    const params = {
      workspace_id: workspaceId,
      estimate_id: estimate.estimate_id,
      enable: enable,
      //vanity_slug: "string",
      //password_hash: "string"
    };

    api
      .post('/estimate/share', params)
      .then(response => {
        if (response.data.status.code === 0) {
          dispatch({ type: 'UPDATE_ESTIMATE', payload: response.data.result });

          if (!enable) {
            enqueueSnackbar('Deleted link to "' + estimate.name + '"', {
              variant: 'info',
            });
          }
        } else {
          // handle error
          setError(response.data.status.message);
        }
      })
      .catch(e => {
        setError(e.toString());
      })
      .then(() => {
        setLoading(false);
      });
  };

  const onDeleteLink = () => {
    let options = {
      description: 'This link will no longer work for anyone who has it.',
      confirmationText: 'Delete',
      dialogProps: {
        maxWidth: 'xs',
      },
    };

    confirm(options)
      .then(() => {
        createShareLink(false);
      })
      .catch(e => {});
  };

  return (
    <>
      <Button variant="contained" color="primary" startIcon={<ShareIcon />} onClick={onOpenDialog}>
        Share
      </Button>

      <Dialog open={open} onClose={onCloseDialog} fullWidth>
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <StyledAvatar>
              <ShareIcon />
            </StyledAvatar>

            <Box ml={1}>Share estimate</Box>
          </Box>
        </DialogTitle>
        {loading ? (
          <DialogContent>
            <Box height="200px" display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          </DialogContent>
        ) : estimate.share_slug ? (
          <DialogContent>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Box display="flex" alignItems="center">
                <VisibilityIcon color="action" />
                <Box ml={1}>People with this link can view.</Box>
              </Box>
              <Button color="secondary" onClick={onDeleteLink}>
                Delete link
              </Button>
            </Box>
            <Divider />
            <CopyLink link={window.location.origin + '/share/' + estimate.share_slug} />
          </DialogContent>
        ) : (
          <DialogContent>
            <Box
              height="200px"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Fab variant="extended" color="primary" onClick={() => createShareLink(true)}>
                Create share link
              </Fab>
              <Box mt={2}>
                <Typography variant="body2" color={error ? 'error' : 'textSecondary'}>
                  {error ? error : 'Estimate is not currently shared.'}
                </Typography>
              </Box>
            </Box>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={onCloseDialog}>Done</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const StyledAvatar = styled(Avatar)`
  background-color: ${({ theme }) => theme.palette.accent.main};
`;

export default ShareDialog;
