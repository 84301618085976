import React from 'react';
import styled from 'styled-components';
import logo from './MainLogo.png';

const Logo = () => <LogoImage src={logo} alt="Estimake logo" />;

const LogoImage = styled.img`
  width: 150px;
`;

export default Logo;
