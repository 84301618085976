import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import CopyIcon from '@material-ui/icons/FileCopyOutlined';
import LaunchIcon from '@material-ui/icons/Launch';

import { useSnackbar } from 'notistack';

const CopyLink = ({ link }) => {
  const { enqueueSnackbar } = useSnackbar();
  const inputRef = React.useRef(null);

  React.useEffect(() => {
    inputRef.current.select();
  }, []);

  const onCopy = () => {
    inputRef.current.select();
    document.execCommand('copy');
    enqueueSnackbar('Share link copied to clipboard', { variant: 'info' });
  };

  return (
    <TextField
      inputRef={inputRef}
      variant="outlined"
      margin="normal"
      fullWidth
      value={link}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip title="Copy to clipboard">
              <IconButton onClick={onCopy}>
                <CopyIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Open in a new tab">
              <IconButton component="a" target="_blank" href={link}>
                <LaunchIcon color="action" />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
      }}
    />
  );
};

CopyLink.propTypes = {
  link: PropTypes.string.isRequired,
};

export default CopyLink;
