import React from 'react';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';

import useAuthToken from './../helpers/useAuthToken';
import Estimates from './Estimates/Estimates';

const Customers = React.lazy(() => import('./Customers/Customers'));
const Projects = React.lazy(() => import('./Projects/Projects'));
const Profile = React.lazy(() => import('./Profile/Profile'));
const Workspaces = React.lazy(() => import('./Workspaces/Workspaces'));

const Dash = () => {
  const { path } = useRouteMatch();
  const { authToken } = useAuthToken();

  if (!authToken) return <Redirect to="/login" />;

  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Redirect exact from={path} to={`${path}/estimates`} />

        <Route path={`${path}/estimates`} component={Estimates} />
        <Route path={`${path}/customers`} component={Customers} />
        <Route path={`${path}/projects`} component={Projects} />
        <Route path={`${path}/profile`} component={Profile} />
        <Route path={`${path}/workspaces`} component={Workspaces} />
      </Switch>
    </React.Suspense>
  );
};

export default Dash;
