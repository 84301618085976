import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import { saveAs } from 'file-saver';

import api from '../../helpers/api';
import SubmenuButton from './SubmenuButton';

const ExportMenu = ({ workspaceId, estimateId }) => {
  const [loading, setLoading] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onExport = format => {
    handleClose();
    setLoading(true);

    const params = {
      workspace_id: workspaceId,
      estimate_id: estimateId,
      format: format,
    };

    api
      .post('/estimate/export', params)
      .then(response => {
        if (response.data.status.code === 0) {
          const blob = b64toBlob(response.data.result.base64, response.data.result.mime);
          saveAs(blob, response.data.result.name);
        } else {
          // handle error
        }
      })
      .catch(e => {
        console.log(e);
      })
      .then(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <SubmenuButton
        onClick={handleClick}
        size="small"
        endIcon={loading && <CircularProgress size="1rem" />}
      >
        Export
      </SubmenuButton>

      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => onExport('xlsx')}>Microsoft Excel (.xlsx)</MenuItem>
        <MenuItem onClick={() => onExport('xls')}>Microsoft Excel 2007 (.xls)</MenuItem>
        <MenuItem onClick={() => onExport('ods')}>OpenDocument format (.ods)</MenuItem>
        <MenuItem onClick={() => onExport('csv')}>Comma-separated values (.csv)</MenuItem>
      </Menu>
    </div>
  );
};

const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });

  return blob;
};

export default ExportMenu;
