import { useSessionStorage, useLocalStorage } from './useBrowserStorage';

export const REMEMBER_KEY = 'remember_me';
export const TOKEN_KEY = 'auth_token';

const useAuthToken = () => {
  const [rememberMe, setRememberMe] = useLocalStorage(REMEMBER_KEY, true);
  const [localAuthToken, setLocalAuthToken] = useLocalStorage(TOKEN_KEY, null);
  const [sessionAuthToken, setSessionAuthToken] = useSessionStorage(TOKEN_KEY, null);

  const setAuthToken = (auth_token, remember_me) => {
    if (!auth_token) {
      setLocalAuthToken(null);
      setSessionAuthToken(null);
    } else {
      setLocalAuthToken(remember_me ? auth_token : null);
      setSessionAuthToken(remember_me ? null : auth_token);
    }

    if (typeof remember_me !== 'undefined') {
      setRememberMe(remember_me);
    }
  };

  return {
    rememberMe: rememberMe,
    authToken: rememberMe ? localAuthToken : sessionAuthToken,
    setAuthToken: setAuthToken,
  };
};

export default useAuthToken;
