import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { CountriesContext, CurrenciesContext } from '../helpers/Context';

const CustomerInfo = ({ customer, setCustomer, errors }) => {
  const countries = React.useContext(CountriesContext);
  const currencies = React.useContext(CurrenciesContext);

  const [country, setCountry] = React.useState(null);
  const [currency, setCurrency] = React.useState(null);

  React.useEffect(() => {
    if (customer && customer.country_id) {
      const country = countries.find(country => country.country_id === customer.country_id);

      if (country) {
        setCountry(country);
      }
    } else {
      setCountry(null);
    }

    if (customer && customer.currency_id) {
      const currency = currencies.find(currency => currency.currency_id === customer.currency_id);

      if (currency) {
        setCurrency(currency);
      }
    } else {
      setCurrency(null);
    }
  }, [customer, countries, currencies]);

  const onChangeCountry = newCountry => {
    setCountry(newCountry);
    setCustomer(customer => {
      return {
        ...customer,
        country_id: newCountry ? newCountry.country_id : null,
      };
    });

    if (!currency && newCountry && newCountry.currency_id) {
      const found_currency = currencies.find(
        currency => currency.currency_id === newCountry.currency_id
      );

      if (found_currency) {
        setCurrency(found_currency);
        setCustomer(customer => {
          return { ...customer, currency_id: found_currency.currency_id };
        });
      }
    }
  };

  return (
    <>
      <Grid item>
        <TextField
          autoFocus
          variant="outlined"
          required
          fullWidth
          label="Company name / Customer Name"
          name="company_name"
          value={customer.name}
          onChange={e => setCustomer({ ...customer, name: e.target.value })}
          error={errors.hasOwnProperty('name')}
        />
      </Grid>
      <Grid item>
        <TextField
          variant="outlined"
          fullWidth
          label="Contact Name"
          name="contact_name"
          value={customer.contact_name}
          onChange={e => setCustomer({ ...customer, contact_name: e.target.value })}
          error={errors.hasOwnProperty('contact_name')}
        />
      </Grid>
      <Grid item>
        <Grid container spacing={1}>
          <Grid item sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              label="Contact Email"
              name="contact_email"
              type="email"
              value={customer.contact_email}
              onChange={e => setCustomer({ ...customer, contact_email: e.target.value })}
              error={errors.hasOwnProperty('contact_email')}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              label="Contact Phone"
              name="contact_phone"
              value={customer.contact_phone}
              onChange={e => setCustomer({ ...customer, contact_phone: e.target.value })}
              error={errors.hasOwnProperty('contact_phone')}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <TextField
          variant="outlined"
          fullWidth
          label="Address Line 1"
          name="address_line_1"
          value={customer.address_line_1}
          onChange={e => setCustomer({ ...customer, address_line_1: e.target.value })}
          error={errors.hasOwnProperty('address_line_1')}
        />
      </Grid>
      <Grid item>
        <TextField
          variant="outlined"
          fullWidth
          label="Address Line 2"
          name="address_line_2"
          value={customer.address_line_2}
          onChange={e => setCustomer({ ...customer, address_line_2: e.target.value })}
          error={errors.hasOwnProperty('address_line_2')}
        />
      </Grid>
      <Grid item>
        <TextField
          variant="outlined"
          fullWidth
          label="City"
          name="city"
          value={customer.city}
          onChange={e => setCustomer({ ...customer, city: e.target.value })}
          error={errors.hasOwnProperty('city')}
        />
      </Grid>
      <Grid item>
        <Grid container spacing={1}>
          <Grid item sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              label="State / Province / Region"
              name="state"
              value={customer.state}
              onChange={e => setCustomer({ ...customer, state: e.target.value })}
              error={errors.hasOwnProperty('state')}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              label="ZIP / Postal Code"
              name="zip"
              value={customer.zip}
              onChange={e => setCustomer({ ...customer, zip: e.target.value })}
              error={errors.hasOwnProperty('zip')}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Autocomplete
              options={countries}
              getOptionLabel={country => country.name}
              value={country}
              onChange={(event, newValue) => {
                onChangeCountry(newValue);
              }}
              renderInput={params => <TextField {...params} label="Country" variant="outlined" />}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              options={currencies}
              getOptionLabel={currency =>
                currency ? currency.name + ' (' + currency.iso_code + ')' : ''
              }
              value={currency}
              onChange={(event, newValue) => {
                setCurrency(newValue);
              }}
              renderInput={params => <TextField {...params} label="Currency" variant="outlined" />}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CustomerInfo;
