import React from 'react';

const initialState = null;

const Reducer = (state, action) => {
  let i, j;

  switch (action.type) {
    case 'UPDATE_ESTIMATE':
      return {
        ...state,
        ...action.payload,
      };
    case 'SET_ESTIMATE_RATE':
      return {
        ...state,
        rate: action.payload.rate,
        currency_id: action.payload.currency_id,
      };
    case 'ADD_CATEGORY':
      state.categories.push(action.payload);

      return {
        ...state,
      };
    case 'REMOVE_CATEGORY':
      return {
        ...state,
        categories: state.categories.filter(
          category => category.estimate_category_id !== action.payload
        ),
      };
    case 'UPDATE_CATEGORY':
      let category_updated = false;

      for (i in state.categories) {
        if (state.categories[i].estimate_category_id === action.payload.estimate_category_id) {
          category_updated = true;
          state.categories[i] = {
            ...state.categories[i],
            ...action.payload,
          };
        }
      }

      if (!category_updated) {
        state.categories.push(action.payload);
      }

      return {
        ...state,
      };
    case 'SET_CATEGORY_RATE':
      return {
        ...state,
        categories: state.categories.map(category => {
          if (category.estimate_category_id === action.payload.estimate_category_id) {
            return { ...category, rate: action.payload.rate };
          }

          return { ...category };
        }),
      };
    case 'SET_CATEGORIES':
      return {
        ...state,
        categories: action.payload,
      };
    case 'SET_ITEMS':
      if (!action.payload.category) {
        return {
          ...state,
          items: action.payload.items,
        };
      }

      return {
        ...state,
        categories: state.categories.map(category => {
          if (category.estimate_category_id === action.payload.category.estimate_category_id) {
            return { ...category, items: action.payload.items };
          }

          return { ...category };
        }),
      };
    case 'REMOVE_ITEM':
      for (i in state.categories) {
        state.categories[i].items = state.categories[i].items.filter(
          item => item.estimate_item_id !== action.payload
        );
      }

      let ret = {
        ...state,
        items: state.items.filter(item => item.estimate_item_id !== action.payload),
      };

      return ret;
    case 'UPDATE_ITEM':
      let found = false;

      for (i in state.categories) {
        for (j in state.categories[i].items) {
          if (state.categories[i].items[j].estimate_item_id === action.payload.estimate_item_id) {
            state.categories[i].items[j] = {
              ...state.categories[i].items[j],
              ...action.payload,
            };

            found = true;

            break;
          }
        }
      }

      for (i in state.items) {
        if (state.items[i].estimate_item_id === action.payload.estimate_item_id) {
          found = true;
          state.items[i] = {
            ...state.items[i],
            ...action.payload,
          };
        }
      }

      if (!found) {
        if (action.payload.estimate_category_id) {
          for (i in state.categories) {
            if (state.categories[i].estimate_category_id === action.payload.estimate_category_id) {
              state.categories[i].items.push(action.payload);
              break;
            }
          }
        } else {
          state.items.push(action.payload);
        }
      }

      return {
        ...state,
      };
    case 'SET_ONLINE_MEMBERS':
      return {
        ...state,
        online_clients: action.payload,
      };
    case 'SET_LOCKED_ITEMS':
      var k;

      for (i in state.categories) {
        for (j in state.categories[i].items) {
          let locked_client_id = null;

          for (k in action.payload) {
            let lock_info = action.payload[k];

            if (state.categories[i].items[j].estimate_item_id === lock_info.estimate_item_id) {
              locked_client_id = lock_info.client_id;
            }
          }

          state.categories[i].items[j].locked_client_id = locked_client_id;
        }
      }

      for (i in state.items) {
        let locked_client_id = null;

        for (k in action.payload) {
          let lock_info = action.payload[k];

          if (state.items[i].estimate_item_id === lock_info.estimate_item_id) {
            locked_client_id = lock_info.client_id;
          }
        }

        state.items[i].locked_client_id = locked_client_id;
      }

      return {
        ...state,
      };
    case 'SET_LOCKED_SECTIONS':
      return {
        ...state,
        locked_sections: action.payload,
      };
    default:
      return state;
  }
};

export const EstimateStoreContext = React.createContext(initialState);

const EstimateStore = ({ children }) => {
  const [state, dispatch] = React.useReducer(Reducer, initialState);

  return (
    <EstimateStoreContext.Provider value={[state, dispatch]}>
      {children}
    </EstimateStoreContext.Provider>
  );
};

export const useEstimateStore = () => {
  const [estimate, dispatch] = React.useContext(EstimateStoreContext);

  return {
    estimate: estimate,
    dispatch: dispatch,
  };
};

export default EstimateStore;
