import React from 'react';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { CurrenciesContext } from '../../helpers/Context';
import { useGlobalState } from '../GlobalState';
import RateLabel from '../RateLabel';
import SubmenuButton from './SubmenuButton';
import { useWebsocketConn } from './RealTimeProvider';
import { useEstimateStore } from './EstimateStore';

const SetRate = ({ onSave, defaultRate, defaultCurrencyId }) => {
  const ws = useWebsocketConn();
  const currencies = React.useContext(CurrenciesContext);
  const { globalState } = useGlobalState();
  const { estimate } = useEstimateStore();

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');

  const [rate, setRate] = React.useState(0);
  const [currency, setCurrency] = React.useState(null);

  React.useEffect(() => {
    if (defaultCurrencyId) {
      setCurrency(currencies.find(currency => currency.currency_id === defaultCurrencyId));
    }
  }, [currencies, defaultCurrencyId]);

  React.useEffect(() => {
    setRate(defaultRate);
  }, [defaultRate]);

  const onCloseDialog = () => {
    setOpen(false);
    setError('');
    ws.send({ action: 'unlockSection' });
  };

  const onOpenDialog = () => {
    setOpen(true);

    ws.send({
      action: 'lockSection',
      payload: 'rate',
    });
  };

  const save = async (rate, currency_id) => {
    setLoading(true);

    try {
      onSave && (await onSave(rate, currency_id));
      onCloseDialog();
    } catch (error) {
      setError(error.toString());
    }

    setLoading(false);
  };

  const onRemoveRate = async () => {
    await save(null, null);
  };

  const lockInfo = estimate.locked_sections?.find(lockInfo => lockInfo.section === 'rate');

  return (
    <>
      <Tooltip title="Click to update rate">
        <SubmenuButton size="small" onClick={onOpenDialog} disabled={Boolean(lockInfo)}>
          Rate:&nbsp;
          <RateLabel rate={{ rate: defaultRate, currency_id: defaultCurrencyId }} />
        </SubmenuButton>
      </Tooltip>

      <Dialog open={open} onClose={onCloseDialog} fullWidth>
        <DialogTitle>Set rate</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select a preconfigured rate from your workspace or enter a custom one.
          </DialogContentText>

          <Grid container direction="column" spacing={2}>
            {globalState.client.current_workspace.rates &&
              globalState.client.current_workspace.rates.length > 0 && (
                <Grid item>
                  <List>
                    {globalState.client.current_workspace.rates.map(rate => (
                      <ListItem key={rate.workspace_rate_id} divider>
                        <ListItemText>
                          <RateLabel rate={rate} showDefault />
                        </ListItemText>
                        <ListItemSecondaryAction>
                          <Button
                            variant="outlined"
                            color="primary"
                            title="Use this rate"
                            onClick={async e => save(rate.rate, rate.currency_id)}
                          >
                            Use
                          </Button>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              )}
            <Grid item>
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="rate_amount"
                    label="Rate per hour"
                    value={rate}
                    onChange={e => setRate(e.target.value)}
                    error={error.length > 0}
                    helperText={error}
                  />
                </Grid>
                <Grid item sm={6}>
                  <Autocomplete
                    fullWidth
                    options={currencies}
                    getOptionLabel={currency =>
                      currency ? currency.name + ' (' + currency.iso_code + ')' : ''
                    }
                    value={currency}
                    onChange={(e, newValue) => setCurrency(newValue)}
                    renderInput={params => (
                      <TextField
                        {...params}
                        required
                        margin="normal"
                        fullWidth
                        label="Currency"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>{loading && <LinearProgress />}</Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onRemoveRate} color="secondary" disabled={!currency}>
            Clear rate
          </Button>
          <Button onClick={onCloseDialog}>Cancel</Button>
          <Button onClick={e => save(rate, currency ? currency.currency_id : null)} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SetRate;
