import React from 'react';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import CheckIcon from '@material-ui/icons/CheckOutlined';
import AddIcon from '@material-ui/icons/AddOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import styled, { css } from 'styled-components';
import { useConfirm } from 'material-ui-confirm';
import throttle from 'lodash/throttle';

import api from '../../helpers/api';
import ItemAvatar from '../ItemAvatar';
import { useGlobalState } from '../GlobalState';
import { StyledInputBase } from './EditableField';
import ItemDescription from './ItemDescription';
import TooltipIconButton from './TooltipIconButton';
import { useEstimateStore } from './EstimateStore';
import { useWebsocketConn } from './RealTimeProvider';

const CategoryItem = ({ category, item, dragHandleProps }) => {
  const confirm = useConfirm();

  const ws = useWebsocketConn();

  const { globalState } = useGlobalState();

  const { estimate, dispatch } = useEstimateStore();

  const [itemName, setItemName] = React.useState('');
  const [itemTimeFrom, setItemTimeFrom] = React.useState('');
  const [itemTimeTo, setItemTimeTo] = React.useState('');

  let [timeUnits, setTimeUnits] = React.useState('hours');

  const [needsSaving, setNeedsSaving] = React.useState(false);
  const [saveLoading, setSaveLoading] = React.useState(false);

  const [deleteLoading, setDeleteLoading] = React.useState(false);
  const [expanded, setExpanded] = React.useState(true);

  const [autocompleteItem, setAutocompleteItem] = React.useState(null);
  const [autocompleteOptions, setAutocompleteOptions] = React.useState([]);

  const [timeError, setTimeError] = React.useState(false);

  const inputRef = React.useRef(null);
  const focusInput = React.useCallback(() => inputRef?.current.focus(), [inputRef]);

  const isLocked =
    item && item.locked_client_id != null && item.locked_client_id !== globalState.client.client_id;

  React.useEffect(() => {
    if (item) {
      setItemName(item.name ? item.name : '');
      setTimeUnits(item.estimate_time_type ? item.estimate_time_type : 'hours');
      setItemTimeFrom(item.estimate_time_from ? item.estimate_time_from : '');
      setItemTimeTo(item.estimate_time_to ? item.estimate_time_to : '');
    }
  }, [item]);

  const fetchSuggestions = React.useMemo(
    () =>
      throttle((query, callback) => {
        const params = {
          workspace_id: estimate.workspace_id,
          estimate_id: estimate.estimate_id,
          query: query,
        };

        api.post('/item/autocomplete', params).then(response => {
          //console.log(response.data.result);
          if (response.data.status.code === 0) {
            callback(response.data.result);
          } else {
            callback([]);
          }
        });
      }, 300),
    [estimate]
  );

  React.useEffect(() => {
    let active = true;

    if (item && itemName === item.name) {
      return undefined;
    }

    if (itemName === '') {
      setAutocompleteOptions([]);
      return undefined;
    }

    fetchSuggestions(itemName, results => {
      if (active) {
        setAutocompleteOptions(results);
      }
    });

    return () => {
      active = false;
    };
  }, [autocompleteItem, itemName, fetchSuggestions, item]);

  const onSave = () => {
    if (isLocked) {
      return;
    }

    if (!needsSaving) {
      ws.send({ action: 'unlockItem' });
      return;
    }

    setSaveLoading(true);

    const params = {
      ...item,
      workspace_id: estimate.workspace_id,
      estimate_id: estimate.estimate_id,
      estimate_category_id: category ? category.estimate_category_id : null,
      name: itemName,
      estimate_time_type: timeUnits,
      estimate_time_from: itemTimeFrom,
      estimate_time_to: itemTimeTo,
    };

    const url = params.estimate_item_id ? '/item/update' : '/item/create';

    api
      .post(url, params)
      .then(response => {
        if (response.data.status.code === 0) {
          setNeedsSaving(false);

          if (!params.estimate_item_id) {
            setItemName('');
            setTimeUnits('hours');
            setItemTimeFrom('');
            setItemTimeTo('');
            focusInput();
          }

          dispatch({ type: 'UPDATE_ITEM', payload: response.data.result });

          ws.send({
            action: 'updatedItem',
            payload: JSON.stringify(response.data.result),
          });
        } else {
          // handle error
        }
      })
      .catch(e => {
        console.log(e);
      })
      .then(() => {
        ws.send({ action: 'unlockItem' });
        setSaveLoading(false);
      });
  };

  const onDelete = () => {
    if (isLocked) {
      return;
    }

    lockItem();

    setDeleteLoading(true);

    let options = {
      description: 'Are you sure you want to delete this task?',
      confirmationText: 'Yes, delete it',
    };

    confirm(options)
      .then(() => {
        const params = {
          workspace_id: estimate.workspace_id,
          estimate_id: estimate.estimate_id,
          estimate_item_id: item.estimate_item_id,
        };

        api
          .post('/item/delete', params)
          .then(response => {
            if (response.data.status.code === 0) {
              setExpanded(false);

              ws.send({
                action: 'deletedItem',
                payload: item.estimate_item_id,
              });
            }
          })
          .catch(e => {
            console.log(e);
          })
          .then(() => {
            setDeleteLoading(false);
          });
      })
      .catch(e => {
        setDeleteLoading(false);
      });
  };

  const dispatchRemoveItem = () => {
    dispatch({ type: 'REMOVE_ITEM', payload: item.estimate_item_id });
  };

  const onTimeChange = e => {
    setTimeError(false);
    let from_value = null;
    let to_value = null;

    const value_parts = e.target.value.split('-');

    if (value_parts.length > 1) {
      from_value = value_parts[0].trim();
      to_value = value_parts[1].trim();
    } else {
      from_value = value_parts[0].trim();
      to_value = value_parts[0].trim();
    }

    if (isNaN(from_value) || isNaN(to_value)) {
      return false;
    }

    if (parseFloat(from_value) > parseFloat(to_value)) {
      setTimeError(true);
    }

    setItemTimeFrom(from_value);
    setItemTimeTo(to_value);
    setNeedsSaving(true);
  };

  const lockItem = () => {
    if (!item) return;
    if (!item.estimate_item_id) return;

    ws.send({
      action: 'requestItemLock',
      payload: item.estimate_item_id,
    });
  };

  const saveOnEnter = e => {
    if (e.keyCode === 13) {
      setTimeout(() => onSave());
    }
  };

  let avatar_text = null;
  let avatar_resource = null;

  if (item) {
    let client_id = item.locked_client_id
      ? item.locked_client_id
      : item.last_updated_client_id
      ? item.last_updated_client_id
      : item.creator_client_id;

    const member = globalState.client.current_workspace.members.find(
      member => client_id === member.client_id
    );

    // const member = globalState.client.current_workspace.members.find(
    //     member => member.client_id === item.locked_client_id
    // );

    if (member) {
      avatar_text = member.client.full_name;
      avatar_resource = member.client.image;
    }
  }

  return (
    <Collapse in={expanded} onExited={dispatchRemoveItem}>
      <ItemCnt>
        <Box display="flex" alignItems="center">
          <IconButton size="small" color="primary" {...dragHandleProps} disabled={!item}>
            <DragIndicatorIcon />
          </IconButton>
        </Box>

        <Box mx={1}>
          {avatar_text || avatar_resource || !item ? (
            <Grow in={true}>
              <ItemAvatar
                text={avatar_text}
                resource={avatar_resource}
                size={4}
                online={item?.locked_client_id}
              />
            </Grow>
          ) : (
            <NoEditor size={4} />
          )}
        </Box>

        <Box flexGrow={1} pr={1}>
          <Autocomplete
            freeSolo
            disableClearable
            options={autocompleteOptions}
            value={autocompleteItem}
            onChange={(event, newValue) => {
              setAutocompleteItem(newValue);

              if (typeof newValue == 'object') {
                setTimeUnits(newValue.estimate_time_type ? newValue.estimate_time_type : 'hours');
                setItemTimeFrom(newValue.estimate_time_from ? newValue.estimate_time_from : '');
                setItemTimeTo(newValue.estimate_time_to ? newValue.estimate_time_to : '');
              }
            }}
            inputValue={itemName}
            onInputChange={(event, newValue) => {
              //console.log(newValue);
              setItemName(newValue);
              setNeedsSaving(true);
            }}
            getOptionLabel={option => {
              // Value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }

              return option?.item_name;
            }}
            renderOption={option => (
              <Typography>
                {option.item_name}
                &nbsp; ({option.estimate_time_from}
                {option.estimate_time_from !== option.estimate_time_to && (
                  <>
                    {' - '}
                    {option.estimate_time_to}
                  </>
                )}{' '}
                {option.estimate_time_type})
              </Typography>
            )}
            onBlur={item && onSave}
            onFocus={lockItem}
            disabled={isLocked}
            onKeyUp={saveOnEnter}
            renderInput={params => (
              <div ref={params.InputProps.ref}>
                <StyledInputBase
                  inputRef={inputRef}
                  fullWidth
                  placeholder="New task name"
                  {...params.inputProps}
                />
              </div>
            )}
          />
        </Box>

        <Box pr={1}>
          <ItemDescription item={item} disabled={!item || isLocked} />
        </Box>

        <DottedVerticalDivider />

        <Box pl={1} width="15%">
          <Tooltip title="You can enter a single value or a range. Eg. '5-7'">
            <StyledInputBase
              placeholder="time"
              type="text"
              $textAlign="right"
              onBlur={item && onSave}
              onFocus={lockItem}
              disabled={isLocked}
              onKeyDown={saveOnEnter}
              value={
                itemTimeFrom === itemTimeTo
                  ? itemTimeFrom
                  : itemTimeFrom + ' -' + (itemTimeTo ? ' ' + itemTimeTo : '')
              }
              onChange={e => onTimeChange(e)}
              $error={timeError}
            />
          </Tooltip>
        </Box>

        <Box width="80px">
          <FormControl fullWidth>
            <Select
              disableUnderline
              value={timeUnits}
              disabled={isLocked}
              onChange={e => {
                setTimeUnits(e.target.value);
                timeUnits = e.target.value;
                setNeedsSaving(true);

                if (item) {
                  onSave();
                }
              }}
            >
              <MenuItem value="hours">hours</MenuItem>
              <MenuItem value="days">days</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box>
          {needsSaving || !item ? (
            <TooltipIconButton
              title={item ? 'Save' : 'Add task'}
              size="small"
              onClick={onSave}
              color="secondary"
              disabled={!item && !needsSaving}
              component={FilledIconButton}
            >
              {saveLoading ? (
                <CircularProgress size="1.5rem" color="inherit" />
              ) : item ? (
                <CheckIcon />
              ) : (
                <AddIcon />
              )}
            </TooltipIconButton>
          ) : (
            <TooltipIconButton
              title="Delete task"
              disabled={!item || isLocked}
              size="small"
              color="primary"
              onClick={onDelete}
            >
              {deleteLoading ? <CircularProgress size="1.5rem" /> : <DeleteIcon />}
            </TooltipIconButton>
          )}
        </Box>
      </ItemCnt>
    </Collapse>
  );
};

const FilledIconButton = styled(IconButton)`
  color: white;
  background-color: ${({ theme }) => theme.palette.success.main};

  &:hover {
    background-color: ${({ theme }) => theme.palette.success.light};
  }
`;

const DottedVerticalDivider = styled(Box)`
  border-left: 1px dotted ${({ theme }) => theme.palette.divider};
  height: auto;
  align-self: stretch;
`;

const ItemCnt = styled(Box)`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(1, 1, 1, 3)};
  transition: all 0.2s ease-in-out;
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  background-color: white;

  &:hover {
    box-shadow: ${({ theme }) => theme.shadows[2]};
  }
`;

const NoEditor = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 50%;

  ${props =>
    props.size &&
    css`
      width: ${({ theme }) => theme.spacing(props.size)}px;
      height: ${({ theme }) => theme.spacing(props.size)}px;
    `}
`;

export default CategoryItem;
