import React from 'react';
import axios from 'axios';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createBrowserHistory } from 'history';
import { createGlobalStyle } from 'styled-components';
import { Helmet } from 'react-helmet';
import { Router, Switch, Route } from 'react-router-dom';

import Home from './pages/Home/Home';
import Dash from './pages/Dash';
import Share from './pages/Share';
import PasswordReset from './pages/PasswordReset';
import EmailVerifySuccess from './pages/EmailVerifySuccess';
import EmailVerifyError from './pages/EmailVerifyError';
import ForgottenPasswordRequest from './pages/ForgottenPasswordRequest';

import GlobalState from './components/GlobalState';
import WebsiteRoute from './components/WebsiteRoute';
import CookieAlert from './components/CookieAlert';

import api from './helpers/api';
import { CountriesContext, CurrenciesContext } from './helpers/Context';

const Privacy = React.lazy(() => import('./pages/Privacy'));
const Terms = React.lazy(() => import('./pages/Terms'));
const Cookies = React.lazy(() => import('./pages/Cookies'));
const Contact = React.lazy(() => import('./pages/Contact'));
const Guestimate = React.lazy(() => import('./pages/Guestimate'));
const Page404 = React.lazy(() => import('./pages/Page404'));
const Signup = React.lazy(() => import('./pages/Signup'));
const LogIn = React.lazy(() => import('./pages/Login'));
const DashLayout = React.lazy(() => import('./components/DashLayout'));

export const history = createBrowserHistory();

const App = () => {
  const [countries, setCountries] = React.useState([]);
  const [currencies, setCurrencies] = React.useState([]);

  React.useEffect(() => {
    const getCountries = api.post('/general/get-countries');
    const getCurrencies = api.post('/general/get-currency-rates');

    axios
      .all([getCountries, getCurrencies])
      .then(
        axios.spread((...responses) => {
          setCountries(responses[0].data.result);
          setCurrencies(responses[1].data.result);
        })
      )
      .catch(errors => {
        console.log(errors);
      });
  }, []);

  return (
    <CurrenciesContext.Provider value={currencies}>
      <CountriesContext.Provider value={countries}>
        <GlobalStyle />

        <Helmet defaultTitle="Estimake" titleTemplate={`%s - Estimake`}>
          <meta
            name="description"
            content="Free project estimation tool. Create professional estimates in minutes."
          />
          <meta
            name="keywords"
            content="estimate, estimate tool, estimate template, quote generator, estimate generator"
          />
        </Helmet>

        <GlobalState>
          <Router history={history}>
            <CookieAlert />

            <React.Suspense fallback={<Loader />}>
              <Switch>
                <Route path="/dash">
                  <DashLayout>
                    <Dash />
                  </DashLayout>
                </Route>

                <WebsiteRoute path="/login" component={LogIn} />
                <WebsiteRoute path="/signup" component={Signup} />
                <WebsiteRoute path="/request-reset-password" component={ForgottenPasswordRequest} />
                <WebsiteRoute path="/password-reset" component={PasswordReset} />
                <WebsiteRoute path="/contact" component={Contact} />
                <WebsiteRoute path="/privacy" component={Privacy} />
                <WebsiteRoute path="/terms" component={Terms} />
                <WebsiteRoute path="/cookies" component={Cookies} />
                <WebsiteRoute path="/email-verify-success" component={EmailVerifySuccess} />
                <WebsiteRoute path="/email-verify-error" component={EmailVerifyError} />
                <WebsiteRoute path="/guestimate/:workspaceId/:estimateId" component={Guestimate} />

                <Route path="/share/:slug" component={Share} />

                <Route path="/" exact component={Home} />

                <WebsiteRoute component={Page404} />
              </Switch>
            </React.Suspense>
          </Router>
        </GlobalState>
      </CountriesContext.Provider>
    </CurrenciesContext.Provider>
  );
};

const GlobalStyle = createGlobalStyle`
    html, body {
        height: 100%;
        ${'' /* background-color: white; */}
    }

    #root {
        height: 100%;
    }

    img, video {
      max-width: 100%;
      vertical-align: middle;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
`;

const Loader = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    height="100vh"
  >
    <CircularProgress />
    <Box pt={2}>
      <Typography variant="caption">Loading...</Typography>
    </Box>
  </Box>
);

export default App;
