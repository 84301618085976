import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#8664A8',
    },
    accent: {
      main: '#6C8093',
    },

    // secondary: {
    //     main: '#6C8093',
    // },

    // error: {
    //     main: red.A400,
    // },
    // background: {
    //     default: '#fff',
    // },
  },
  shape: {
    borderRadius: 4,
  },
  overrides: {
    MuiSkeleton: {
      root: {
        borderRadius: 4,
      },
    },
  },
});

export default theme;
