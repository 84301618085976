import React from 'react';
import Alert from '@material-ui/lab/Alert';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';

import { Link as RouterLink } from 'react-router-dom';

import { useLocalStorage } from '../helpers/useBrowserStorage';

const CookieAlert = () => {
  const [consented, setConsented] = useLocalStorage('cookieConsent', false);

  if (consented) return null;

  const onConsent = () => {
    setConsented(true);
  };

  return (
    <CookieAlertCnt>
      <Alert
        severity="info"
        action={
          <Button color="inherit" size="small" variant="outlined" onClick={onConsent}>
            Got it
          </Button>
        }
      >
        {'By using Estimake, you consent to our '}
        <Link variant="body2" component={RouterLink} to="/terms">
          terms
        </Link>
        {'. We use cookies to offer you our service as described '}
        <Link variant="body2" component={RouterLink} to="/cookies">
          here
        </Link>
        .
      </Alert>
    </CookieAlertCnt>
  );
};

const CookieAlertCnt = styled.div`
  position: fixed;
  bottom: 16px;
  z-index: 1000;
  left: 50%;
  transform: translateX(-50%);

  & .MuiAlert-root {
    border: 1px solid currentcolor;
  }

  ${({ theme }) => `
    ${theme.breakpoints.down('sm')} {
      left: 0;
      bottom: 0;
      transform: translateX(0);
    }
  `}
`;

export default CookieAlert;
