import React from 'react';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import styled from 'styled-components';

import api from '../../helpers/api';
import useAuthToken from '../../helpers/useAuthToken';
import RateLabel from '../RateLabel';
import CurrencyAmount from '../CurrencyAmount';
import EditableField from './EditableField';
import { useEstimateStore } from './EstimateStore';
import AddCategory from './AddCategory';
import Category from './Category';
import ManageRate from './ManageRate';
import EstimateIcon from './EstimateIcon';
import ManageWorkspace from './ManageWorkspace';
import ManageCustomer from './ManageCustomer';
import ShareDialog from './ShareDialog';
import { OnlineMembers, useWebsocketConn } from './RealTimeProvider';
import ExportMenu from './ExportMenu';

const ManageEsimate = ({ workspaceId, estimateId }) => {
  const ws = useWebsocketConn();
  const { authToken } = useAuthToken();
  const { estimate, dispatch } = useEstimateStore();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  React.useEffect(() => {
    api
      .post('/estimate/get', {
        workspace_id: workspaceId,
        estimate_id: estimateId,
      })
      .then(response => {
        if (response.data.status.code === 0) {
          dispatch({ type: 'UPDATE_ESTIMATE', payload: response.data.result });
        } else {
          // handle error
        }
      })
      .catch(e => {
        console.log(e);
      });
  }, [estimateId, workspaceId, dispatch, authToken]);

  const onSaveEstimateName = async new_name => {
    const params = {
      ...estimate,
      name: new_name,
      check_archive: false,
    };

    ws.send({
      action: 'updatedEstimate',
      payload: JSON.stringify({
        name: new_name,
      }),
    });

    await api.post('/estimate/update', params);
  };

  const onSaveRate = async (rate, currency_id) => {
    let floatRate = parseFloat(rate);

    if (!floatRate && currency_id) {
      throw new Error('Excpected amount');
    }

    if (!currency_id) {
      floatRate = null;
    }

    const params = {
      ...estimate,
      rate: floatRate,
      currency_id: currency_id,
      check_archive: false,
    };

    let response = await api.post('/estimate/update', params);

    if (response.data.status.code === 0) {
      dispatch({
        type: 'SET_ESTIMATE_RATE',
        payload: { rate: floatRate, currency_id: currency_id },
      });

      ws.send({
        action: 'updatedEstimate',
        payload: JSON.stringify({
          rate: floatRate,
          currency_id: currency_id,
        }),
      });
    } else {
      throw new Error(response.data.status.message);
    }
  };

  const onDragEnd = result => {
    // Reordered categories
    if (result.type === 'CATEGORY') {
      const reorderedCategories = Array.from(estimate.categories);
      const [removed] = reorderedCategories.splice(result.source.index, 1);
      reorderedCategories.splice(result.destination.index, 0, removed);

      dispatch({ type: 'SET_CATEGORIES', payload: reorderedCategories });

      const params = {
        workspace_id: workspaceId,
        estimate_id: estimateId,
        categories: reorderedCategories.map(cat => cat.estimate_category_id),
      };

      api.post('/category/order', params);
    }

    // Reordered item
    // TODO: maybe delete item if it's dragged outside? - result.destination == null
    if (result.type === 'CATEGORY_ITEM' && result.destination) {
      const srcCategory = estimate.categories.find(
        cat => result.source.droppableId === 'dropable_' + cat.estimate_category_id
      );
      const dstCategory = estimate.categories.find(
        cat => result.destination.droppableId === 'dropable_' + cat.estimate_category_id
      );

      const srcItems = Array.from(srcCategory ? srcCategory.items : estimate.items);
      const [movedItem] = srcItems.splice(result.source.index, 1);

      movedItem.estimate_category_id = dstCategory ? dstCategory.estimate_category_id : null;

      let dstItems = Array.from(
        srcCategory === dstCategory ? srcItems : dstCategory ? dstCategory.items : estimate.items
      );
      dstItems.splice(result.destination.index, 0, movedItem);

      dispatch({
        type: 'SET_ITEMS',
        payload: { category: srcCategory, items: srcItems },
      });
      dispatch({
        type: 'SET_ITEMS',
        payload: { category: dstCategory, items: dstItems },
      });

      let promise = new Promise(resolutionFunc => resolutionFunc('dummy'));

      // update dragged item category if it's changed
      if (srcCategory !== dstCategory) {
        const params = {
          ...movedItem,
          workspace_id: workspaceId,
          estimate_id: estimateId,
        };

        promise = api.post('/item/update', params);
      }

      // order items in dst category
      const params = {
        workspace_id: workspaceId,
        estimate_id: estimateId,
        estimate_category_id: dstCategory ? dstCategory.estimate_category_id : null,
        items: dstItems.map(item => item.estimate_item_id),
      };

      promise.then(() => api.post('/item/order', params));
    }
  };

  if (!estimate?.items) {
    return (
      <Paper elevation={10} square>
        <Box display="flex" justifyContent="center" py={20}>
          <CircularProgress />
        </Box>
      </Paper>
    );
  }

  let price_from = 0;
  let price_to = 0;
  let hours_from = 0;
  let hours_to = 0;

  estimate.items.forEach(item => {
    const item_hours_from =
      item.estimate_time_type === 'days'
        ? item.estimate_time_from * 8
        : item.estimate_time_from
        ? item.estimate_time_from
        : 0;
    const item_hours_to =
      item.estimate_time_type === 'days'
        ? item.estimate_time_to * 8
        : item.estimate_time_to
        ? item.estimate_time_to
        : 0;

    price_from += item_hours_from * estimate.rate;
    price_to += item_hours_to * estimate.rate;
    hours_from += item_hours_from;
    hours_to += item_hours_to;
  });

  estimate.categories.forEach(category => {
    const category_rate = category.rate ? category.rate : estimate.rate;

    category.items.forEach(item => {
      const item_hours_from =
        item.estimate_time_type === 'days'
          ? item.estimate_time_from * 8
          : item.estimate_time_from
          ? item.estimate_time_from
          : 0;
      const item_hours_to =
        item.estimate_time_type === 'days'
          ? item.estimate_time_to * 8
          : item.estimate_time_to
          ? item.estimate_time_to
          : 0;

      price_from += item_hours_from * category_rate;
      price_to += item_hours_to * category_rate;
      hours_from += item_hours_from;
      hours_to += item_hours_to;
    });
  });

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Paper elevation={10} square>
        <Box py={2} px={mobile ? 2 : 3} mb={4}>
          <Grid container direction="column" spacing={1} wrap="nowrap">
            <Grid item>
              <Grid container direction="column">
                <Grid item container alignItems="center">
                  <Grid item>
                    <Box display="flex">
                      <StyledEstimateIcon />
                    </Box>
                  </Grid>
                  <Grid item xs={5}>
                    <Grid container direction="column">
                      <Grid item>
                        <EditableField
                          value={estimate.name}
                          onSave={onSaveEstimateName}
                          fullWidth
                          $fontSize="17px"
                        />
                      </Grid>
                      <Grid item>
                        <Box display="flex">
                          <Box pl={0.5}>
                            <ManageRate
                              defaultRate={estimate.rate}
                              defaultCurrencyId={estimate.currency_id}
                              onSave={onSaveRate}
                            />
                          </Box>
                          <Box>
                            <ExportMenu workspaceId={workspaceId} estimateId={estimateId} />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <GridGrow></GridGrow>
                  <Grid item>
                    <Box mr={2}>
                      <OnlineMembers />
                    </Box>
                  </Grid>
                  <Grid item>
                    <ShareDialog
                      estimate={estimate}
                      dispatch={dispatch}
                      workspaceId={workspaceId}
                    />
                  </Grid>
                </Grid>

                <Grid item>
                  <Box pt={0.2}>
                    <Divider />
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Box display="flex" flexDirection={mobile ? 'column' : 'row'}>
                <Box flexGrow={1} flexBasis={0} py={2}>
                  <ManageWorkspace estimate={estimate} />
                </Box>

                <Box px={3}></Box>

                <Box flexGrow={1} flexBasis={0} py={2}>
                  <ManageCustomer />
                </Box>
              </Box>
            </Grid>

            <Grid item>
              <Droppable droppableId="droppable-1" type="CATEGORY">
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {estimate.categories.map((category, index) => (
                      <Draggable
                        key={category.estimate_category_id}
                        draggableId={'category_' + category.estimate_category_id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <CategoryCnt ref={provided.innerRef} {...provided.draggableProps}>
                            <Category
                              category={category}
                              dragHandleProps={provided.dragHandleProps}
                            />
                          </CategoryCnt>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>

              <Category />
            </Grid>

            <Grid item>
              <Box pt={2}>
                <Grid container wrap="nowrap" direction={mobile ? 'column' : 'row'}>
                  <Grid item xs>
                    <AddCategory />
                  </Grid>
                  <Grid item xs>
                    <Box ml={mobile ? 0 : 3} pb={1} mt={mobile ? 4 : 0}>
                      <TableContainer>
                        <Table size="small">
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                <AccentTypography variant="body1">
                                  <Box fontWeight={500} component="span">
                                    Hours
                                  </Box>
                                </AccentTypography>
                              </TableCell>
                              <TableCell align="right">
                                <Box fontWeight={500} component="span">
                                  {hours_from}h
                                  {hours_from !== hours_to && (
                                    <>
                                      {' - '}
                                      {hours_to}h
                                    </>
                                  )}
                                </Box>
                              </TableCell>
                            </TableRow>
                            {estimate.currency_id && (
                              <>
                                <TableRow>
                                  <TableCell>
                                    <AccentTypography variant="body1">
                                      Default rate
                                    </AccentTypography>
                                  </TableCell>
                                  <TableCell align="right">
                                    <RateLabel
                                      rate={{
                                        rate: estimate.rate,
                                        currency_id: estimate.currency_id,
                                      }}
                                    />
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <StyledTableCell>
                                    <AccentTypography variant="body1">
                                      <Box fontWeight={500} component="span">
                                        Total
                                      </Box>
                                    </AccentTypography>
                                  </StyledTableCell>
                                  <StyledTableCell align="right">
                                    <Typography variant="body1">
                                      <Box fontWeight={500} component="span">
                                        <CurrencyAmount
                                          amount={price_from}
                                          currency_id={estimate.currency_id}
                                        />
                                        {price_from !== price_to && (
                                          <>
                                            {' - '}
                                            <CurrencyAmount
                                              amount={price_to}
                                              currency_id={estimate.currency_id}
                                            />
                                          </>
                                        )}
                                      </Box>
                                    </Typography>
                                  </StyledTableCell>
                                </TableRow>
                              </>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </DragDropContext>
  );
};

const CategoryCnt = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

const StyledTableCell = styled(TableCell)`
  border-bottom: 2px solid ${({ theme }) => theme.palette.accent.main};
`;

export const AccentTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.accent.main};
`;

const GridGrow = styled(Grid)`
  flex-grow: 1;
`;

const StyledEstimateIcon = styled(EstimateIcon)`
  margin-left: -5px;
  font-size: 46px;
  color: ${({ theme }) => theme.palette.accent.main};
`;

export default ManageEsimate;
