import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import CurrencyAmount from './CurrencyAmount';

const RateLabel = ({ rate, showDefault }) => {
  if (!rate.rate || !rate.currency_id) {
    return 'not set';
  }

  return (
    <>
      <strong>
        <CurrencyAmount amount={rate.rate} currency_id={rate.currency_id} />
      </strong>
      /h
      {showDefault && rate.is_default && (
        <Typography color="secondary" component={'span'} variant="caption">
          {' '}
          (Default)
        </Typography>
      )}
    </>
  );
};

RateLabel.defaultProps = {
  rate: {
    rate: null,
    currency_id: null,
    is_default: false,
  },
  showDefault: false,
};

RateLabel.propTypes = {
  rate: PropTypes.shape({
    rate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    currency_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    is_default: PropTypes.bool,
  }),
  showDefault: PropTypes.bool,
};

export default RateLabel;
