import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grow from '@material-ui/core/Grow';
import { useWebsocketConn } from './RealTimeProvider';

import api from '../../helpers/api';
import { useEstimateStore } from './EstimateStore';

const AddCategory = () => {
  const { estimate, dispatch } = useEstimateStore();

  const ws = useWebsocketConn();

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [name, setName] = React.useState('');
  const [error, setError] = React.useState('');

  const onSave = () => {
    setLoading(true);

    const params = {
      workspace_id: estimate.workspace_id,
      estimate_id: estimate.estimate_id,
      name: name,
    };

    api
      .post('/category/create', params)
      .then(response => {
        if (response.data.status.code === 0) {
          dispatch({ type: 'UPDATE_CATEGORY', payload: response.data.result });

          ws.send({
            action: 'updatedCategory',
            payload: JSON.stringify(response.data.result),
          });

          onCloseField();
        } else {
          if (response.data.errors.name) {
            setError(Object.values(response.data.errors.name)[0]);
          } else {
            setError('An unknown error occured.');
          }
        }
      })
      .catch(e => {
        setError(e.toString());
      })
      .then(() => {
        setLoading(false);
      });
  };

  const onCloseField = () => {
    setOpen(false);
    setName('');
    setError('');
  };

  const onOpenField = () => {
    setOpen(true);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      onSave();
    }
  };

  if (!open) {
    return (
      <Button variant="outlined" size="small" onClick={onOpenField} color="primary">
        Add category
      </Button>
    );
  } else {
    return (
      <Grow in={true}>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={7}>
                <TextField
                  autoFocus
                  size="small"
                  variant="outlined"
                  margin="none"
                  required
                  fullWidth
                  id="new_category_name"
                  name="new_category_name"
                  placeholder="Ex. Frontend, Backend, API ..."
                  value={name}
                  onChange={e => setName(e.target.value)}
                  onKeyDown={handleKeyDown}
                  error={error.length > 0}
                />
              </Grid>
              <Grid item>
                <Button variant="contained" size="small" onClick={onSave} color="primary">
                  Add
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" size="small" onClick={onCloseField} color="secondary">
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {error && (
            <Grid item>
              <Typography variant="body2" color="error">
                {error}
              </Typography>
            </Grid>
          )}
          {loading && (
            <Grid item>
              <LinearProgress />
            </Grid>
          )}
        </Grid>
      </Grow>
    );
  }
};

export default AddCategory;
