import React from 'react';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import PencilIcon from '@material-ui/icons/CreateOutlined';

import { CurrenciesContext } from '../../helpers/Context';
import RateLabel from '../RateLabel';

const SetRate = ({ onSave, defaultRate, currencyId }) => {
  const currencies = React.useContext(CurrenciesContext);

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');

  const [rate, setRate] = React.useState('');
  const [currency, setCurrency] = React.useState(null);

  React.useEffect(() => {
    if (currencyId) {
      setCurrency(currencies.find(currency => currency.currency_id === currencyId));
    }
  }, [currencies, currencyId]);

  React.useEffect(() => {
    if (defaultRate) {
      setRate(defaultRate);
    }
  }, [defaultRate]);

  const onCloseDialog = () => {
    setOpen(false);
  };

  const onOpenDialog = () => {
    setOpen(true);
  };

  const save = async rate => {
    setLoading(true);

    try {
      onSave && (await onSave(rate));
      setOpen(false);
    } catch (error) {
      setError(error.toString());
    }

    setLoading(false);
  };

  const onRemoveRate = async () => {
    await save(null);
  };

  return (
    <>
      <Typography variant="body2" component="span">
        Rate:{' '}
      </Typography>
      <Typography
        variant="body2"
        component="span"
        color={currencyId && defaultRate ? 'textPrimary' : 'textSecondary'}
      >
        <RateLabel rate={{ rate: defaultRate, currency_id: currencyId }} />
      </Typography>
      <Tooltip title="Click to update rate">
        <IconButton size="small" color="primary" onClick={onOpenDialog}>
          <PencilIcon fontSize="small" />
        </IconButton>
      </Tooltip>

      <Dialog open={open} onClose={onCloseDialog} fullWidth>
        <DialogTitle>Set category rate</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Rate per hour applied only to tasks in this category. Set to "0" to use the estimate
            rate.
          </DialogContentText>

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="rate_amount"
            label="Rate per hour"
            value={rate}
            onChange={e => setRate(e.target.value)}
            error={error.length > 0}
            helperText={error}
            InputProps={{
              endAdornment: currency && (
                <InputAdornment position="end">{currency.symbol}</InputAdornment>
              ),
            }}
          />

          {loading && <LinearProgress />}
        </DialogContent>
        <DialogActions>
          <Button onClick={onRemoveRate} color="secondary" disabled={!defaultRate}>
            Clear rate
          </Button>
          <Button onClick={onCloseDialog}>Cancel</Button>
          <Button onClick={e => save(rate)} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SetRate;
