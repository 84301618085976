import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

const TooltipIconButton = ({ title, disabled, children, ...rest }) => {
  if (disabled) {
    return (
      <IconButton {...rest} disabled>
        {children}
      </IconButton>
    );
  }

  return (
    <Tooltip title={title} disabled={disabled}>
      <IconButton {...rest}>{children}</IconButton>
    </Tooltip>
  );
};

export default TooltipIconButton;
