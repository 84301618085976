import React from 'react';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';

const HeaderForm = props => (
  <Paper>
    <Form>{props.children}</Form>
  </Paper>
);

const Form = styled.form`
  display: flex;
  padding: ${({ theme }) => theme.spacing(1)}px;

  & > .MuiTextField-root {
    flex-grow: 1;
    margin: ${({ theme }) => theme.spacing(1)}px;
  }

  & > .MuiFormControlLabel-root {
    margin: ${({ theme }) => theme.spacing(1)}px;
  }

  & > .MuiAutocomplete-root {
    flex-grow: 1;
    margin: ${({ theme }) => theme.spacing(1)}px;
  }
`;

export default HeaderForm;
