import React from 'react';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import MuiAddIcon from '@material-ui/icons/AddOutlined';
import styled from 'styled-components';

const AddButton = ({ title, ...rest }) => (
  <Tooltip title={title}>
    <Fab color="primary" variant={title ? 'extended' : 'round'} {...rest}>
      <AddIcon /> {title}
    </Fab>
  </Tooltip>
);

const AddIcon = styled(MuiAddIcon)`
  margin-right: ${({ theme }) => theme.spacing(1)}px;
`;

export default AddButton;
