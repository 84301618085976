export const estimatedTimeFormat = new Intl.NumberFormat(navigator.language, {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
});

export const dateFormat = new Intl.DateTimeFormat(navigator.language, {
  dateStyle: 'medium',
  timeStyle: 'short',
});

export const fixDateForAllBrowsers = dateString => dateString.replace(/-/g, '/');
