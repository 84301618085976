import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import MailOutlinedIcon from '@material-ui/icons/MailOutlined';
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';

import api from '../helpers/api';
import ItemAvatar from '../components/ItemAvatar';
import DateLabel from '../components/DateLabel';
import TaskDescription from '../components/TaskDescription';
import { useCountryById } from '../hooks/countries';
import { useCurrencyById } from '../hooks/currencies';
import { estimatedTimeFormat } from '../helpers/datetime';

const Share = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { slug } = useParams();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState('');
  const [estimate, setEstimate] = React.useState(null);

  const [fromTime, setFromTime] = React.useState(null);
  const [toTime, setToTime] = React.useState(null);
  const [fromPrice, setFromPrice] = React.useState(null);
  const [toPrice, setToPrice] = React.useState(null);

  const gutterX = mobile ? 0 : 6;
  const workspace = estimate?.workspace;
  const workspaceCountry = useCountryById(workspace?.country_id);
  const customerCountry = useCountryById(estimate?.customer?.country_id);
  const { isoCode: currencyIsoCode } = useCurrencyById(estimate?.currency_id);

  const processEstimate = estimate => {
    // add the uncategorised items to a dummy category
    estimate.categories.push({
      estimate_category_id: -1,
      name: 'Task',
      items: estimate.items,
      rate: estimate.rate,
    });

    let estimate_from_time = 0;
    let estimate_to_time = 0;

    let estimate_from_price = 0;
    let estimate_to_price = 0;

    // calculate cateogry data
    estimate.categories = estimate.categories.map(category => {
      category.max_len = 0;

      category.hours_from = 0;
      category.hours_to = 0;

      category.items.map(item => {
        if (!item.estimate_time_type) item.estimate_time_type = 'hours';

        if (item.estimate_time_type === 'days') {
          item.estimate_time_type = 'hours';
          item.estimate_time_from *= 8;
          item.estimate_time_to *= 8;
        }

        category.hours_from += item.estimate_time_from;
        category.hours_to += item.estimate_time_to;

        item.formatted_from = estimatedTimeFormat.format(item.estimate_time_from);
        item.formatted_to = estimatedTimeFormat.format(item.estimate_time_to);

        category.max_len = Math.max(category.max_len, item.formatted_to.length);
        category.max_len = Math.max(
          category.max_len,
          estimatedTimeFormat.format(category.hours_to).length
        );

        return item;
      });

      if (category.rate === null) {
        category.rate = estimate.rate;
      }

      estimate_from_time += category.hours_from;
      estimate_to_time += category.hours_to;

      category.price_from = category.hours_from * category.rate;
      category.price_to = category.hours_to * category.rate;

      estimate_from_price += category.price_from;
      estimate_to_price += category.price_to;

      return category;
    });

    setFromTime(estimate_from_time);
    setToTime(estimate_to_time);

    setFromPrice(estimate_from_price);
    setToPrice(estimate_to_price);

    return estimate;
  };

  React.useEffect(() => {
    const fetchEstimate = async () => {
      try {
        const response = await api.post('/estimate/get-shared', {
          share_slug: slug,
        });

        if (response.data.status.code === 0) {
          setEstimate(processEstimate(response.data.result));
        } else {
          setError(response.data.status.message);
        }
      } catch (e) {
        console.log(e);
        setError(e.toString());
      } finally {
        setLoading(false);
      }
    };

    fetchEstimate();
  }, [slug]);

  if (loading || error) return <LoadingPage error={error} />;

  return (
    <Background>
      <Helmet>
        <title>{estimate.name}</title>
      </Helmet>

      <StyledContainer maxWidth="md">
        <StyledPaper square elevation={mobile ? 0 : 8}>
          <Box display="flex" flexDirection="column">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              px={gutterX + 4}
              flexDirection={mobile ? 'column' : 'row-reverse'}
            >
              {workspace.image && (
                <ItemAvatar text={workspace.name} resource={workspace.image} size={10} />
              )}
              <Box mt={mobile ? 4 : 0}>
                <Typography
                  component="h1"
                  variant="h5"
                  gutterBottom
                  align={mobile ? 'center' : 'left'}
                >
                  {estimate.name}
                </Typography>
                <Typography variant="overline" style={{ lineHeight: 1 }}>
                  {estimate.project && (
                    <>
                      project: {estimate.project.name}
                      <br />
                    </>
                  )}
                  updated: <DateLabel date={estimate.updated} />
                </Typography>
              </Box>
            </Box>

            <Box display="flex" px={gutterX + 4} flexDirection={mobile ? 'column' : 'row'}>
              <Box flexGrow={1} flexBasis={0} mt={12}>
                <Typography color="textSecondary" variant="subtitle1">
                  From
                </Typography>
                <Divider />
                <Typography variant="h6" gutterBottom>
                  {workspace.name}
                </Typography>
                {workspace.address_line_1 && (
                  <Typography variant="body2" color="textSecondary">
                    {workspace.address_line_1}
                  </Typography>
                )}
                {workspace.address_line_2 && (
                  <Typography variant="body2" color="textSecondary">
                    {workspace.address_line_2}
                  </Typography>
                )}

                {(workspace.city || workspace.state || workspace.zip) && (
                  <Typography variant="body2" color="textSecondary">
                    {workspace.city}
                    {workspace.city && ', '}
                    {workspace.state}
                    {workspace.state && ' '}
                    {workspace.zip}
                  </Typography>
                )}

                {workspaceCountry && (
                  <Typography variant="body2" color="textSecondary" gutterBottom>
                    {workspaceCountry.name}
                  </Typography>
                )}
              </Box>

              <Box px={4} />

              <Box flexGrow={1} flexBasis={0} mt={12}>
                {estimate.customer && (
                  <>
                    <Typography color="textSecondary" variant="subtitle1">
                      For
                    </Typography>
                    <Divider />
                    <Typography variant="h6" gutterBottom>
                      {estimate.customer.name}
                    </Typography>
                    {estimate.customer.address_line_1 && (
                      <Typography variant="body2" color="textSecondary">
                        {estimate.customer.address_line_1}
                      </Typography>
                    )}
                    {estimate.customer.address_line_2 && (
                      <Typography variant="body2" color="textSecondary">
                        {estimate.customer.address_line_2}
                      </Typography>
                    )}

                    {(estimate.customer.city ||
                      estimate.customer.state ||
                      estimate.customer.zip) && (
                      <Typography variant="body2" color="textSecondary">
                        {estimate.customer.city}
                        {estimate.customer.city && ', '}
                        {estimate.customer.state}
                        {estimate.customer.state && ' '}
                        {estimate.customer.zip}
                      </Typography>
                    )}

                    {customerCountry && (
                      <Typography variant="body2" color="textSecondary" gutterBottom>
                        {customerCountry.name}
                      </Typography>
                    )}

                    {estimate.customer &&
                      false &&
                      (estimate.customer.contact_name || estimate.customer.contact_email) && (
                        <Typography variant="body2" color="textSecondary">
                          {estimate.customer.contact_name}
                          {estimate.customer.contact_email && ' <'}
                          {estimate.customer.contact_email}
                          {estimate.customer.contact_email && '>'}
                        </Typography>
                      )}
                  </>
                )}
              </Box>
            </Box>

            {estimate.categories
              .filter(category => category.items.length > 0)
              .map(category => (
                <Box pt={12} px={gutterX} key={category.estimate_category_id}>
                  <CategoryTableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <HeadTableCell>
                            <Typography variant="h6">{category.name}</Typography>
                          </HeadTableCell>
                          <HeadTableCell align="right">Estimated time (h)</HeadTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {category.items.map(item => (
                          <TableRow key={item.estimate_item_id}>
                            <TableCell>
                              <Typography variant="body1" gutterBottom={Boolean(item.description)}>
                                {item.name}
                              </Typography>
                              {item.description && (
                                <Typography variant="body2" color="textSecondary">
                                  <TaskDescription description={item.description} />
                                </Typography>
                              )}
                            </TableCell>
                            <TableCell align="right">
                              <Box fontFamily="Monospace" fontSize={14} minWidth="150px">
                                {item.estimate_time_from !== item.estimate_time_to ? (
                                  <>
                                    {item.formatted_from}
                                    {' - '}
                                    {[
                                      ...Array(category.max_len - item.formatted_to.length).keys(),
                                    ].map(i => (
                                      <React.Fragment key={i}>&nbsp;</React.Fragment>
                                    ))}
                                    {item.formatted_to}
                                  </>
                                ) : (
                                  item.formatted_from
                                )}
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </CategoryTableContainer>
                  <CategoryFooterGrid container>
                    <Grid item md={6} xs={4} />
                    <Grid item md={6} xs={8}>
                      <TableContainer>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell>Hours</TableCell>
                              <TableCell align="right">
                                <Box fontFamily="Monospace" fontSize={14} fontWeight="bold">
                                  {category.hours_from !== category.hours_to ? (
                                    <>
                                      {estimatedTimeFormat.format(category.hours_from)}
                                      {' - '}
                                      {estimatedTimeFormat.format(category.hours_to)}
                                    </>
                                  ) : (
                                    estimatedTimeFormat.format(category.hours_from)
                                  )}
                                </Box>
                              </TableCell>
                            </TableRow>
                            {currencyIsoCode && (
                              <>
                                <TableRow>
                                  <TableCell>{`Rate (${currencyIsoCode} / hour)`}</TableCell>
                                  <TableCell align="right">
                                    <Box fontFamily="Monospace" fontSize={14}>
                                      {estimatedTimeFormat.format(category.rate)}
                                    </Box>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>TOTAL ({currencyIsoCode})</TableCell>
                                  <TableCell align="right">
                                    <Box fontFamily="Monospace" fontSize={14} fontWeight="bold">
                                      {Math.round(category.price_from)}
                                      {category.price_from !== category.price_to && (
                                        <>
                                          {' - '}
                                          {Math.round(category.price_to)}
                                        </>
                                      )}
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              </>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </CategoryFooterGrid>
                </Box>
              ))}

            <FooterBox
              pt={8}
              pb={10}
              px={gutterX + 4}
              mt={10}
              style={{ pageBreakBefore: 'always' }}
            >
              <Box display="flex" flexDirection="column">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  flexDirection={mobile ? 'column' : 'row'}
                >
                  <Box flexGrow={1}>
                    <FooterTypography variant="overline" color="textSecondary">
                      Tasks
                    </FooterTypography>
                    <FooterDivider light />
                    <Box py={3}>
                      <FooterMainTypography variant="h4">
                        {estimate.categories.reduce(
                          (accumulator, category) => accumulator + category.items.length,
                          0
                        )}
                      </FooterMainTypography>
                    </Box>
                  </Box>
                  <Box flexGrow={1}>
                    <FooterTypography variant="overline" color="textSecondary">
                      Hours
                    </FooterTypography>
                    <FooterDivider light />
                    <Box py={3}>
                      <FooterMainTypography variant="h4">
                        {estimatedTimeFormat.format(fromTime)}
                        {fromTime !== toTime && (
                          <>
                            {' - '}
                            {estimatedTimeFormat.format(toTime)}
                          </>
                        )}
                      </FooterMainTypography>
                    </Box>
                  </Box>
                  {currencyIsoCode && (
                    <Box flexGrow={1} textAlign={mobile ? 'left' : 'right'}>
                      <FooterTypography variant="overline" color="textSecondary">
                        Grand total
                      </FooterTypography>
                      <FooterDivider light />
                      <Box color="info.main" py={3}>
                        <FooterMainTypography variant="h4">
                          {Math.round(fromPrice)}
                          {fromPrice !== toPrice && (
                            <>
                              {' - '}
                              {Math.round(toPrice)}
                            </>
                          )}
                          {' ' + currencyIsoCode}
                        </FooterMainTypography>
                      </Box>
                    </Box>
                  )}
                </Box>
                <FooterDivider light />
                <WorkspaceFooterInfo workspace={workspace} mobile={mobile} />
              </Box>
            </FooterBox>
          </Box>
        </StyledPaper>
        <Box pt={4} pb={2} color="text.secondary" textAlign="center">
          Created with{' '}
          <Link color="inherit" href="/" target="_blank">
            Estimake.it
          </Link>
        </Box>
      </StyledContainer>
    </Background>
  );
};

const WorkspaceFooterInfo = ({ workspace, mobile }) => {
  let items = [];

  if (workspace.contact_name) {
    items.push(
      <Grid item key="contact_name">
        <IconTypography variant="body2" color="textSecondary">
          <PersonOutlineOutlinedIcon />
          {workspace.contact_name}
        </IconTypography>
      </Grid>
    );
  }

  if (workspace.contact_email) {
    items.push(
      <Grid item key="contact_email">
        <IconTypography variant="body2" color="textSecondary">
          <MailOutlinedIcon />
          {workspace.contact_email}
        </IconTypography>
      </Grid>
    );
  }

  if (workspace.contact_phone) {
    items.push(
      <Grid item key="contact_phone">
        <IconTypography variant="body2" color="textSecondary">
          <PhoneOutlinedIcon />
          {workspace.contact_phone}
        </IconTypography>
      </Grid>
    );
  }

  if (workspace.website) {
    if (!workspace.website.startsWith('http')) {
      workspace.website = 'http://' + workspace.website;
    }

    items.push(
      <Grid item key="website">
        <Tooltip title="Open in a new tab">
          <Link component="a" href={workspace.website} target="_blank">
            <IconTypography variant="body2" color="textSecondary">
              <LanguageOutlinedIcon />
              {workspace.website}
            </IconTypography>
          </Link>
        </Tooltip>
      </Grid>
    );
  }

  if (items.length === 0) {
    return null;
  }

  const divider = (
    <Grid item key="divider">
      <Divider orientation="vertical" />
    </Grid>
  );

  return (
    <Box pt={5}>
      <Grid container justify="space-around" direction={mobile ? 'column' : 'row'} spacing={1}>
        {items.reduce((prev, curr) => [prev, divider, curr])}
      </Grid>
    </Box>
  );
};

const LoadingPage = ({ error }) => (
  <Background>
    <StyledContainer maxWidth="md">
      <StyledPaper square elevation={8}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          height="200px"
          pb={8}
        >
          {error ? (
            <>
              <ErrorIcon color="error" fontSize="large" />
              <Box pt={1}>
                <Typography color="error">{error}</Typography>
              </Box>
            </>
          ) : (
            <>
              <CircularProgress />
              <Box pt={2}>
                <Typography color="textSecondary">Loading</Typography>
              </Box>
            </>
          )}
        </Box>
      </StyledPaper>
    </StyledContainer>
  </Background>
);

const CategoryFooterGrid = styled(Grid)`
  border-top: 1px solid ${({ theme }) => theme.palette.grey[500]};
`;

const CategoryTableContainer = styled(TableContainer)`
  & .MuiTableRow-root:last-child .MuiTableCell-root {
    border-bottom: none;
  }
`;

const IconTypography = styled(Typography)`
  display: flex;
  align-items: center;

  & .MuiSvgIcon-root {
    margin-right: 8px;
    color: ${({ theme }) => theme.palette.info.main};
  }
`;

const FooterBox = styled(Box)`
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
  background-color: ${({ theme }) => theme.palette.grey[100]};
`;

const FooterTypography = styled(Typography)`
  font-size: 14px;
  line-height: 1.8;
`;

const FooterMainTypography = styled(Typography)`
  font-weight: 300;
`;

const FooterDivider = styled(Divider)`
  height: 2px;
`;

const HeadTableCell = styled(TableCell)`
  padding: ${({ theme }) => theme.spacing(1, 2)};
  background-color: ${({ theme }) => theme.palette.info.main};
  color: ${({ theme }) => theme.palette.common.white};
`;

const Background = styled.div`
  min-height: 100%;
  background-color: ${({ theme }) => theme.palette.grey[300]};

  @media print {
    background-color: ${({ theme }) => theme.palette.grey[100]};
  }
`;

const StyledContainer = styled(Container)`
  padding: ${({ theme }) => theme.spacing(4, 2)};

  ${({ theme }) => `
    ${theme.breakpoints.down('sm')} {
      padding: 0;
    }
  `}

  @media print {
    padding: 0;
  }
`;

const StyledPaper = styled(Paper)`
  padding: ${({ theme }) => theme.spacing(8, 0, 0, 0)};

  @media print {
    box-shadow: none;

    .MuiPaper-root,
    .MuiTableCell-body,
    .MuiTypography-colorTextSecondary {
      color: black;
    }
  }
`;

export default Share;
