import React from 'react';
import Paper from '@material-ui/core/Paper';

import Poster from 'images/promo_video_poster.jpg';
import SrcMp4 from 'videos/promo.mp4';
import SrcWebm from 'videos/promo.webm';
import SrcOgv from 'videos/promo.ogv';
import styled, { css } from 'styled-components';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';

const PromoVideo = () => {
  const videoRef = React.useRef();
  const [playing, setPlaying] = React.useState(false);

  const playVideo = React.useCallback(() => {
    videoRef.current.play();
    setPlaying(true);
  }, []);

  const pauseVideo = React.useCallback(() => {
    videoRef.current.pause();
    setPlaying(false);
  }, []);

  return (
    <VideoContainer onClick={playing ? pauseVideo : playVideo}>
      {playing ? <VideoPauseButton /> : <VideoPlayButton />}
      <video ref={videoRef} poster={Poster}>
        <source src={SrcMp4} type="video/mp4" />
        <source src={SrcWebm} type="video/webm" />
        <source src={SrcOgv} type="video/ogg" />
        Your browser does not support the video tag.
      </video>
    </VideoContainer>
  );
};

const VideoContainer = styled(Paper)`
  position: relative;
  font-size: 70px;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: ${({ theme }) => theme.palette.action.disabledBackground};
    opacity: 0;
    pointer-events: none;
    transition: opacity ease-in-out 0.3s;
  }

  &:hover {
    &:before,
    .MuiSvgIcon-root {
      opacity: 1;
      pointer-events: auto;
    }
  }
`;

VideoContainer.defaultProps = { elevation: 4 };

const iconProps = {
  fontSize: 'inherit',
  color: 'primary',
};

const iconStyles = css`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const VideoPlayButton = styled(PlayCircleOutlineIcon)`
  ${iconStyles};
`;

VideoPlayButton.defaultProps = iconProps;

const VideoPauseButton = styled(PauseCircleOutlineIcon)`
  ${iconStyles};
  opacity: 0;
  transition: opacity ease-in-out 0.3s;
`;

VideoPauseButton.defaultProps = iconProps;

export default PromoVideo;
