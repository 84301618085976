import React from 'react';
import styled from 'styled-components';
import Container from '@material-ui/core/Container';

import Header from './Header';
import Footer from './Footer';

const WebsiteLayout = ({ children }) => (
  <Root maxWidth="md">
    <Header />
    <MainContent>{children}</MainContent>
    <Footer />
  </Root>
);

const MainContent = styled.div`
  flex: 1;
`;

const Root = styled(Container)`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

export default WebsiteLayout;
