import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { CountriesContext } from '../helpers/Context';

const WorkspaceInfo = ({ workspace, setWorkspace, errors, xs }) => {
  const countries = React.useContext(CountriesContext);
  const [country, setCountry] = React.useState(null);

  React.useEffect(() => {
    if (workspace && workspace.country_id) {
      const country = countries.find(country => country.country_id === workspace.country_id);

      if (country) {
        setCountry(country);
      }
    } else {
      setCountry(null);
    }
  }, [workspace, countries]);

  return (
    <>
      <Grid item xs={xs}>
        <TextField
          label="Workspace / Company / Your Name"
          name="workspace_name"
          variant="outlined"
          size="small"
          fullWidth
          required
          value={workspace.name}
          onChange={e => setWorkspace({ ...workspace, name: e.target.value })}
        />
      </Grid>
      <Grid item xs={xs}>
        <TextField
          label="Website"
          name="website"
          variant="outlined"
          size="small"
          fullWidth
          value={workspace.website || ''}
          onChange={e => setWorkspace({ ...workspace, website: e.target.value })}
        />
      </Grid>

      <Grid item xs={xs}>
        <TextField
          variant="outlined"
          fullWidth
          size="small"
          label="Contact Name"
          name="contact_name"
          value={workspace.contact_name || ''}
          onChange={e => setWorkspace({ ...workspace, contact_name: e.target.value })}
          error={errors?.contact_name}
        />
      </Grid>
      <Grid item xs={xs}>
        <Grid container spacing={1}>
          <Grid item sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              size="small"
              label="Contact Email"
              name="contact_email"
              type="email"
              value={workspace.contact_email || ''}
              onChange={e => setWorkspace({ ...workspace, contact_email: e.target.value })}
              error={errors?.contact_email}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              size="small"
              label="Contact Phone"
              name="contact_phone"
              value={workspace.contact_phone || ''}
              onChange={e => setWorkspace({ ...workspace, contact_phone: e.target.value })}
              error={errors?.contact_phone}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={xs}>
        <TextField
          variant="outlined"
          fullWidth
          size="small"
          label="Address Line 1"
          name="address_line_1"
          value={workspace.address_line_1 || ''}
          onChange={e => setWorkspace({ ...workspace, address_line_1: e.target.value })}
          error={errors?.address_line_1}
        />
      </Grid>
      <Grid item xs={xs}>
        <TextField
          variant="outlined"
          fullWidth
          size="small"
          label="Address Line 2"
          name="address_line_2"
          value={workspace.address_line_2 || ''}
          onChange={e => setWorkspace({ ...workspace, address_line_2: e.target.value })}
          error={errors?.address_line_2}
        />
      </Grid>
      <Grid item xs={xs}>
        <TextField
          variant="outlined"
          fullWidth
          size="small"
          label="City"
          name="city"
          value={workspace.city || ''}
          onChange={e => setWorkspace({ ...workspace, city: e.target.value })}
          error={errors?.city}
        />
      </Grid>
      <Grid item xs={xs}>
        <Grid container spacing={1}>
          <Grid item sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              size="small"
              label="State / Province / Region"
              name="state"
              value={workspace.state || ''}
              onChange={e => setWorkspace({ ...workspace, state: e.target.value })}
              error={errors?.state}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              size="small"
              label="ZIP / Postal Code"
              name="zip"
              value={workspace.zip || ''}
              onChange={e => setWorkspace({ ...workspace, zip: e.target.value })}
              error={errors?.zip}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={xs}>
        <Autocomplete
          size="small"
          options={countries}
          getOptionLabel={country => country.name}
          value={country}
          onChange={(event, newValue) => {
            setCountry(newValue);
            setWorkspace({
              ...workspace,
              country_id: newValue ? newValue.country_id : null,
            });
          }}
          renderInput={params => <TextField {...params} label="Country" variant="outlined" />}
        />
      </Grid>
    </>
  );
};

export default WorkspaceInfo;
