import React from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grow from '@material-ui/core/Grow';

import Icon from '../components/Icon/Icon';

import { AuthPaper, Avatar, Form, Button } from '../components/Auth';

import api from '../helpers/api';
import LinearProgress from '@material-ui/core/LinearProgress';
import EndStateMessage from '../components/EndStateMessage';

const ForgottenPasswordRequest = () => {
  const [loading, setLoading] = React.useState(false);
  const [general_error, setGeneralError] = React.useState('');

  const [email, setEmail] = React.useState('');

  const [email_error, setEmailError] = React.useState();

  const [submitted, setSubmitted] = React.useState(false);

  const onError = error => {
    setLoading(false);
    console.log(error);
    setGeneralError(error.toString());
  };

  const onForgottenPassword = event => {
    event.preventDefault();

    setEmailError(false);
    setGeneralError('');

    setLoading(true);

    const params = {
      email: email,
    };

    api
      .post('/account/client/forgotten-password-request', params)
      .then(response => {
        setLoading(false);

        if (response.data.status.code !== 0) {
          setGeneralError(response.data.status.message);
        } else {
          setSubmitted(true);
        }
      })
      .catch(e => onError(e));
  };

  let content = null;
  let maxWidth = 'xs';

  if (!submitted) {
    content = (
      <AuthPaper>
        <Avatar>
          <Icon />
        </Avatar>

        <Typography component="h1" variant="h5" gutterBottom>
          Forgot password?
        </Typography>

        <Typography variant="body2" align="center">
          Enter your the email address you registered with, and we'll send you a password reset
          email.
        </Typography>

        <Form noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={e => setEmail(e.target.value)}
            error={email_error}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={event => onForgottenPassword(event)}
          >
            Reset password
          </Button>

          {loading && <LinearProgress />}
          {general_error && (
            <Typography color="error" align="center">
              {general_error}
            </Typography>
          )}
        </Form>
      </AuthPaper>
    );
  } else {
    content = (
      <EndStateMessage
        title="Password Recovery Sent"
        message="Please check your email for further instructions."
      />
    );
    maxWidth = 'lg';
  }

  return (
    <Grow in={true}>
      <Container maxWidth={maxWidth}>{content}</Container>
    </Grow>
  );
};

export default ForgottenPasswordRequest;
