import React from 'react';
import { CurrenciesContext } from '../helpers/Context';

export const useCurrencyById = id => {
  const currencies = React.useContext(CurrenciesContext);

  const currency = React.useMemo(
    () => currencies.find(currency => currency.currency_id === id) || {},
    [currencies, id]
  );

  const { iso_code: isoCode, ...rest } = currency;

  return {
    isoCode,
    ...rest,
  };
};

export const useCurrencyFormatter = id => {
  const { isoCode } = useCurrencyById(id);

  let options = {};

  if (isoCode) {
    options.style = 'currency';
    options.currency = isoCode;
  }

  return new Intl.NumberFormat(navigator.language, options);
};
