import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CogsLogo = props => {
  return (
    <SvgIcon viewBox="412 1030.162 102.376 105.838" {...props}>
      <path
        d=" M 509.579 1055.95 C 509.573 1054.348 509.371 1052.753 508.977 1051.2 L 514.37 1046.781 L 510.072 1039.331 L 503.47 1041.704 C 501.167 1039.501 498.365 1037.888 495.304 1037.004 L 494.186 1030.162 L 485.59 1030.162 L 484.432 1037.004 C 481.321 1037.893 478.53 1039.526 476.249 1041.704 L 469.704 1039.331 L 465.406 1046.775 L 470.759 1051.108 C 470.354 1052.69 470.146 1054.317 470.14 1055.95 C 470.14 1057.601 470.363 1059.188 470.747 1060.718 L 465.406 1065.12 L 469.704 1072.564 L 476.163 1070.117 C 478.455 1072.335 481.28 1073.997 484.432 1074.896 L 485.59 1081.739 L 494.186 1081.739 L 495.309 1074.891 C 498.412 1073.998 501.248 1072.357 503.567 1070.111 L 510.078 1072.57 L 514.376 1065.125 L 508.983 1060.707 C 509.371 1059.151 509.571 1057.554 509.579 1055.95 Z  M 498.456 1055.95 C 498.454 1059.023 496.815 1061.861 494.154 1063.396 C 491.492 1064.931 488.215 1064.93 485.555 1063.393 C 482.894 1061.856 481.256 1059.017 481.257 1055.945 C 481.259 1051.196 485.11 1047.347 489.859 1047.349 C 494.608 1047.35 498.457 1051.201 498.456 1055.95 L 498.456 1055.95 Z "
        fill="rgb(108,128,147)"
      />
      <path
        d=" M 472.85 1100.475 C 472.842 1098.268 472.564 1096.07 472.022 1093.93 L 479.45 1087.844 L 473.529 1077.581 L 464.435 1080.849 C 461.263 1077.815 457.403 1075.593 453.185 1074.376 L 451.646 1064.95 L 439.804 1064.95 L 438.21 1074.376 C 433.923 1075.6 430.078 1077.849 426.936 1080.849 L 417.921 1077.581 L 412 1087.836 L 419.373 1093.804 C 418.813 1095.984 418.524 1098.224 418.513 1100.475 C 418.513 1102.749 418.821 1104.935 419.35 1107.043 L 412 1113.106 L 417.921 1123.361 L 426.81 1119.99 C 429.968 1123.045 433.86 1125.335 438.202 1126.574 L 439.804 1136 L 451.646 1136 L 453.193 1126.566 C 457.467 1125.337 461.374 1123.076 464.569 1119.982 L 473.537 1123.369 L 479.458 1113.114 L 472.029 1107.027 C 472.564 1104.884 472.84 1102.684 472.85 1100.475 Z  M 445.741 1084.164 C 444.643 1084.164 444.173 1085.139 444.137 1085.697 L 443.51 1097.478 C 442.317 1098.228 441.489 1099.517 441.489 1101.034 C 441.489 1103.37 443.407 1105.286 445.741 1105.286 C 445.864 1105.286 445.969 1105.226 446.089 1105.217 L 455.57 1113.723 C 456.007 1114.105 457.138 1114.455 458.151 1113.444 C 459.171 1112.431 458.874 1111.229 458.499 1110.794 L 449.993 1101.103 L 449.993 1101.034 C 449.993 1099.466 449.105 1098.141 447.831 1097.409 L 447.345 1085.697 C 447.318 1085.139 446.832 1084.162 445.741 1084.162 L 445.741 1084.164 Z "
        fillRule="evenodd"
        fill="rgb(134,100,168)"
      />
    </SvgIcon>
  );
};

export default CogsLogo;
