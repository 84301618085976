import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import styled, { keyframes } from 'styled-components';

const Cog = ({ hole, bottom, left, size, color, rotation, speed, reverse }) => {
  return (
    <StyledSvgIcon
      bottom={bottom}
      left={left}
      size={size}
      bgcolor={color}
      rotation={rotation}
      speed={speed}
      reverse={reverse ? 1 : 0}
      viewBox="698.52 1140.262 48.969 51.577"
    >
      <path
        stroke="#f1f1f1"
        d=" M 742.693 1166.05 C 742.687 1164.448 742.485 1162.852 742.091 1161.299 L 747.484 1156.881 L 743.186 1149.431 L 736.584 1151.803 C 734.282 1149.6 731.479 1147.988 728.418 1147.104 L 727.3 1140.262 L 718.704 1140.262 L 717.546 1147.104 C 714.435 1147.992 711.644 1149.626 709.363 1151.803 L 702.818 1149.431 L 698.52 1156.875 L 703.873 1161.208 C 703.468 1162.79 703.26 1164.417 703.254 1166.05 C 703.254 1167.701 703.477 1169.288 703.861 1170.818 L 698.52 1175.219 L 702.818 1182.664 L 709.277 1180.217 C 711.569 1182.434 714.395 1184.096 717.546 1184.996 L 718.704 1191.839 L 727.3 1191.839 L 728.423 1184.99 C 731.526 1184.098 734.362 1182.456 736.682 1180.211 L 743.192 1182.669 L 747.49 1175.225 L 742.097 1170.807 C 742.485 1169.251 742.685 1167.654 742.693 1166.05 Z  M 731.57 1166.05 C 731.569 1169.122 729.929 1171.96 727.268 1173.496 C 724.607 1175.031 721.329 1175.03 718.669 1173.493 C 716.009 1171.956 714.371 1169.116 714.372 1166.044 C 714.373 1161.295 718.224 1157.447 722.974 1157.448 C 727.723 1157.45 731.571 1161.301 731.57 1166.05 L 731.57 1166.05 Z "
      />
    </StyledSvgIcon>
  );
};

const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
`;

const StyledSvgIcon = styled(SvgIcon)`
  color: ${({ bgcolor }) => bgcolor};
  position: absolute;
  bottom: ${({ bottom }) => bottom};
  left: ${({ left }) => left};
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  transform: rotate(${({ rotation }) => rotation}deg);
  animation: ${rotate} ${({ speed }) => speed}s linear infinite;
  animation-direction: ${({ reverse }) => (reverse ? 'reverse' : 'normal')};
  z-index: 1;
`;

export default Cog;
