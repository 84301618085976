import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import styled from 'styled-components';

import api from '../../helpers/api';
import { useGlobalState } from '../GlobalState';
import PrimaryDivider from '../PrimaryDivider';
import ItemAvatar from '../ItemAvatar';
import WorkspaceAvatarEdit from '../WorkspaceAvatarEdit';
import WorkspaceInfo from '../WorkspaceInfo';
import { AccentTypography } from './ManageEstimate';
import { useWebsocketConn } from './RealTimeProvider';

const ManageWorkspace = ({ estimate }) => {
  const { globalState, dispatchGlobalState } = useGlobalState();

  const ws = useWebsocketConn();

  const workspaceId = globalState.client.current_workspace.workspace_id;

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState('');

  const [workspace, setWorkspace] = React.useState({
    name: '',
    website: '',
    contact_name: '',
    contact_phone: '',
    contact_email: '',
    address_line_1: '',
    address_line_2: '',
    city: '',
    state: '',
    zip: '',
    country_id: null,
  });

  React.useEffect(() => {
    const foundWorkspace = globalState.workspaces.find(
      workspace => workspace.workspace_id === parseInt(workspaceId, 10)
    );

    if (foundWorkspace) {
      setWorkspace(foundWorkspace);
    }
  }, [globalState, workspaceId]);

  const onSave = () => {
    setLoading(true);

    api
      .post('/workspace/update', workspace)
      .then(response => {
        if (response.data.status.code !== 0) {
          setErrors(response.data.errors);
        } else {
          onCloseDialog();
          dispatchGlobalState({
            type: 'UPDATE_WORKSPACE',
            payload: response.data.result,
          });
          ws.send({ action: 'workspaceUpdated' });
        }
      })
      .catch(e => {
        console.log(e);
      })
      .then(() => {
        setLoading(false);
      });
  };

  const onCloseDialog = () => {
    setOpen(false);

    ws.send({ action: 'unlockSection' });
  };

  const onOpenDialog = () => {
    setOpen(true);

    ws.send({
      action: 'lockSection',
      payload: 'workspace',
    });
  };

  const lockInfo = estimate.locked_sections?.find(lockInfo => lockInfo.section === 'workspace');
  const lockedBy = globalState.client.current_workspace.members.find(
    member => lockInfo?.client_id === member.client_id
  );

  return (
    <>
      <Box display="flex" flexDirection="column" position="relative">
        {lockedBy && (
          <LockedItemAvatar
            text={lockedBy.client.full_name}
            resource={lockedBy.client.image}
            online
            size={5}
          />
        )}
        <Box display="flex" justifyContent="space-between" alignItems="center" px={1}>
          <AccentTypography variant="h6">From</AccentTypography>
          {workspace.is_owner && (
            <Button
              color="primary"
              size="small"
              onClick={onOpenDialog}
              disabled={Boolean(lockInfo)}
            >
              Edit info
            </Button>
          )}
        </Box>
        <PrimaryDivider />
        <WorkspaceInfoCnt px={1} pt={2} locked={lockInfo}>
          <Box display="flex" alignItems="center" pb={1}>
            <ItemAvatar text={workspace.name} resource={workspace.image} size={4} />
            <Box ml={1}>
              <Typography variant="body1">{workspace.name}</Typography>
            </Box>
          </Box>

          {workspace.contact_email || workspace.contact_name ? (
            <Typography variant="body2" color="textSecondary">
              {workspace.contact_name}
              {workspace.contact_name && ' '}
              {workspace.contact_email && '<'}
              {workspace.contact_email}
              {workspace.contact_email && '>'}
            </Typography>
          ) : null}

          {workspace.contact_phone && (
            <Typography variant="body2" color="textSecondary">
              {workspace.contact_phone}
            </Typography>
          )}
          {workspace.website && (
            <Typography variant="body2" color="textSecondary">
              {workspace.website}
            </Typography>
          )}
        </WorkspaceInfoCnt>
      </Box>

      <Dialog open={open} onClose={onCloseDialog} fullWidth>
        <DialogTitle>
          Your details
          <Typography variant="body2" color="textSecondary">
            Filling more information will look better in exported documents.
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <WorkspaceAvatarEdit workspace={workspace} />
            </Grid>

            <Grid item> </Grid>

            <WorkspaceInfo
              workspace={workspace}
              setWorkspace={setWorkspace}
              errors={errors}
              xs={12}
            />

            {loading && (
              <Grid item>
                <LinearProgress />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseDialog}>Cancel</Button>
          <Button onClick={onSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const WorkspaceInfoCnt = styled(Box)`
  filter: blur(${({ locked }) => (locked ? '4px' : 0)});
`;

const LockedItemAvatar = styled(ItemAvatar)`
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: ${({ theme, size }) => theme.spacing(-size / 2)}px;
`;

export default ManageWorkspace;
