import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import { green } from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconCheckmark from '@material-ui/icons/CheckCircleOutlineOutlined';

const EndStateMessage = props => {
  return (
    <Box py={20}>
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <Grow in={true} timeout={1200}>
            {props.icon ? props.icon : <StyledIcon style={{ color: green[500] }} />}
          </Grow>
        </Grid>
        <Grid item>
          <Typography variant="h2" component="h1">
            {props.title ?? ''}
          </Typography>
        </Grid>
        <Grid item>
          <Box py={2}>
            <Typography variant="body1">{props.message ?? ''}</Typography>
          </Box>
        </Grid>
        {props.children ? <Grid item>{props.children}</Grid> : null}
      </Grid>
    </Box>
  );
};

EndStateMessage.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  icon: PropTypes.element,
};

const StyledIcon = styled(IconCheckmark)`
  font-size: 72px;
`;

export default EndStateMessage;
