import axios from 'axios';

import { history } from '../App';
import { TOKEN_KEY, REMEMBER_KEY } from './useAuthToken';

export const API_URL = process.env.REACT_APP_API_URL;

const instance = axios.create({
  baseURL: `${API_URL}/api`,
});

instance.interceptors.request.use(
  config => {
    let remember_me = JSON.parse(window.localStorage.getItem(REMEMBER_KEY));

    let token = remember_me
      ? window.localStorage.getItem(TOKEN_KEY)
      : window.sessionStorage.getItem(TOKEN_KEY);

    config.headers['apiauthtoken'] = JSON.parse(token);

    return config;
  },
  error => {
    console.log(error);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    // Check for auth exception
    if (response.data.status.code === 401) {
      localStorage.removeItem(TOKEN_KEY);
      sessionStorage.removeItem(TOKEN_KEY);

      history.push('/login?err=' + response.data.status.message);
    }

    return response;
  },
  error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    // hopefully this doesn't happen on the login page or this is an infinate loop
    // TODO: also maybe we should logout
    //history.push('/login?err='+error.toString());
    return Promise.reject(error);
  }
);

export default instance;
