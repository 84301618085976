import styled from 'styled-components';
import MUIButton from '@material-ui/core/Button';
import MUIAvatar from '@material-ui/core/Avatar';

export const AuthPaper = styled.div`
  margin-top: ${({ theme }) => theme.spacing(8)}px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Avatar = styled(MUIAvatar)`
  margin: ${({ theme }) => theme.spacing(1)}px;
  background-color: white;
`;

export const Form = styled.form`
  width: 100%; // Fix IE 11 issue.
  margin-top: ${({ theme }) => theme.spacing(1)}px;
`;

export const Button = styled(MUIButton)`
  margin: ${({ theme }) => theme.spacing(3, 0, 2)};
`;
