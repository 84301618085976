import React from 'react';
import PropTypes from 'prop-types';

const TaskDescription = ({ description }) => {
  const string_description = description ? description : '';

  return string_description.split('\n').map((item, key) => {
    return (
      <React.Fragment key={key}>
        {item}
        <br />
      </React.Fragment>
    );
  });
};

TaskDescription.defaultProps = {
  description: '',
};

TaskDescription.propTypes = {
  description: PropTypes.string.isRequired,
};

export default TaskDescription;
