import React from 'react';
import Box from '@material-ui/core/Box';
import Grow from '@material-ui/core/Grow';
import Fab from '@material-ui/core/Fab';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import styled from 'styled-components';

import { Link as RouterLink } from 'react-router-dom';

import useAuthToken from '../helpers/useAuthToken';
import EndStateMessage from '../components/EndStateMessage';

const EmailVerifyError = () => {
  const { authToken } = useAuthToken();

  return (
    <Grow in={true}>
      <EndStateMessage
        title="Verification error"
        message="There was an error with verifying your email address."
        icon={<StyledIcon color="secondary" />}
      >
        <Box py={2}>
          {authToken ? (
            <Fab to="/dash/estimates" variant="extended" color="primary" component={RouterLink}>
              Go to Estimates
            </Fab>
          ) : (
            <Fab to="/login" variant="extended" color="primary" component={RouterLink}>
              Log In
            </Fab>
          )}
        </Box>
      </EndStateMessage>
    </Grow>
  );
};

const StyledIcon = styled(ErrorOutlineOutlinedIcon)`
  font-size: 72px;
`;

export default EmailVerifyError;
