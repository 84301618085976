import React from 'react';
import { CountriesContext } from '../helpers/Context';

export const useCountryById = id => {
  const countries = React.useContext(CountriesContext);

  const country = React.useMemo(() => countries.find(country => country.country_id === id) || {}, [
    countries,
    id,
  ]);

  return country;
};
