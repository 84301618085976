import React from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { Link as RouterLink } from 'react-router-dom';

import api from './../helpers/api';
import ItemAvatar from './ItemAvatar';
import useAuthToken from './../helpers/useAuthToken';
import { Skeleton } from '@material-ui/lab';

const HeaderLogin = () => {
  const { authToken } = useAuthToken();
  const { profile, loading } = useProfile(authToken);

  if (loading) return <Skeleton variant="rect" width={225} height={36} />;

  if (!profile) {
    return (
      <>
        <Box mr={2}>
          <Button to="/login" component={RouterLink}>
            Log In
          </Button>
        </Box>
        <Button to="/signup" variant="outlined" color="primary" component={RouterLink}>
          Sign Up
        </Button>
      </>
    );
  }

  if (profile?.login === GUEST) {
    return (
      <Box mr={2}>
        <Button
          to="/signup"
          variant="outlined"
          color="primary"
          component={RouterLink}
          style={{ textAlign: 'center' }}
        >
          Complete your profile
        </Button>
      </Box>
    );
  }

  return (
    <Button
      to="/dash/estimates"
      variant="outlined"
      color="primary"
      component={RouterLink}
      endIcon={<ItemAvatar text={profile?.full_name} resource={profile?.image} size={3} />}
    >
      Go to your estimates
    </Button>
  );
};

const useProfile = authToken => {
  const [profile, setProfile] = React.useState(null);
  const [loading, setLoading] = React.useState(Boolean(authToken));

  React.useEffect(() => {
    if (!authToken) return;
    setLoading(true);

    // TODO: extract getProfile
    const fetchData = async () => {
      try {
        const response = await api.post('/account/client/get-profile');
        const result = (await response?.data?.result) || null;
        setProfile(result);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [authToken]);

  return {
    profile,
    loading,
  };
};

const GUEST = 'guest';

export default HeaderLogin;
