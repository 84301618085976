import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';

import styled, { css } from 'styled-components';

import { API_URL } from '../helpers/api';

const ItemAvatar = ({ text, resource, size, grey, loading, online, ...rest }) => {
  if (loading) {
    return (
      <StyledAvatar size={size} grey={grey} {...rest}>
        <StyledCircularProgress />
      </StyledAvatar>
    );
  }

  let initials;

  if (text) {
    text = text.trim();
    let res = text.split(' ');

    if (res.length > 1) {
      initials = res[0].charAt(0).toUpperCase() + res[res.length - 1].charAt(0).toUpperCase();
    } else {
      initials = res[0].substr(0, 2).toUpperCase();
    }

    if (online) text += ' (online)';
  } else {
    text = '';
  }

  return (
    <Tooltip title={text}>
      {resource ? (
        <StyledAvatar
          alt={text}
          src={API_URL + resource.resource_uri}
          size={size}
          grey={grey}
          $online={online}
          {...rest}
        />
      ) : text ? (
        <StyledAvatar size={size} grey={grey} $online={online} {...rest}>
          {initials}
        </StyledAvatar>
      ) : (
        <StyledAvatar size={size} grey={grey} $online={online} {...rest} />
      )}
    </Tooltip>
  );
};

ItemAvatar.propTypes = {
  text: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loading: PropTypes.bool,
  grey: PropTypes.bool,
  online: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  resource: PropTypes.shape({
    resource_uri: PropTypes.string.isRequired,
  }),
};

const StyledAvatar = styled(Avatar)`
  ${props =>
    props.size &&
    css`
      width: ${({ theme }) => theme.spacing(props.size)}px;
      height: ${({ theme }) => theme.spacing(props.size)}px;
    `}
  ${props =>
    props.grey &&
    css`
      filter: greyscale(100%) opacity(50%);
    `}

    ${props =>
    props.$online &&
    css`
      box-shadow: 0 0 1px 1px #fff, 0 0 2px 2px ${({ theme }) => theme.palette.success.light};
    `}
`;

const StyledCircularProgress = styled(CircularProgress)`
  color: white;
`;

export default ItemAvatar;
